import React from 'react';
import './Invoice.css'; 
import jsPDF from 'jspdf'; 
import Header from './Header/Header';
import { useSelector } from 'react-redux';
import logo from './images/Group.png';
import Loader from './Loader';
import { useTranslation } from 'react-i18next'; // استدعاء useTranslation

const Invoice = () => {
  const { t } = useTranslation(); // استدعاء الترجمة
  const loadingInvoice = useSelector(state => state.Data.loadingInvoice);
  const invoices = useSelector(state => state.Data.invoice);

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const doc = new jsPDF();
    doc.text(t("invoice.invoiceTitle"), 10, 10);
    doc.save("invoice.pdf");
  };

  return (
    <div className="invoice">
      <Header />
      <div className='invoice_pading'>
        {loadingInvoice && <Loader />}
        <div className="header">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>

        <div className="store-info">
          <p><strong>{t("invoice.storeName")}:</strong> {invoices?.data?.data?.invoice.order?.store_name}</p>
          <p><strong>{t("invoice.shippingAddress")}:</strong> {invoices?.data?.data?.invoice.order?.shipping_address}</p>
          <p><strong>{t("invoice.orderDate")}:</strong> {invoices?.data?.data?.invoice.order?.order_date}</p>
          <p><strong>{t("invoice.orderNumber")}:</strong> {invoices?.data?.data?.invoice.order?.order_code}</p>
          <p><strong>{t("invoice.customerName")}:</strong> {invoices?.data?.data?.invoice.order?.user_name}</p>
          <p><strong>{t("invoice.customerPhone")}:</strong> {invoices?.data?.data?.invoice.user?.phone_number}</p>
          <p><strong>{t("invoice.orderStatus")}:</strong> {invoices?.data?.data?.invoice.order?.order_status}</p>
        </div>

        <table className="product-table">
          <thead>
            <tr>
              <th>{t("invoice.productImage")}</th>
              <th>{t("invoice.productName")}</th>
              <th>{t("invoice.quantity")}</th>
              <th>{t("invoice.price")}</th>
              <th>{t("invoice.discount")}</th>
              <th>{t("invoice.total")}</th>
            </tr>
          </thead>
          <tbody>
            {invoices?.data?.data?.invoice.order?.order_items.map((product, index) => (
              <tr key={index}>
                <td><img src={product.product_image} alt={product.name} className="product-image" /></td>
                <td>{product.product}</td>
                <td>{product.quantity}</td>
                <td>{product.product_price} $</td>
                <td>{product.discount} $</td>
                <td>{product.price_after_discount} $</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="totals">
          <p><strong>{t("invoice.customerNote")}:</strong> {invoices?.data?.data?.invoice.order?.note}</p>
          <p><strong>{t("invoice.totalQuantity")}:</strong> {invoices?.data?.data?.invoice.order?.total_quantity}</p>
          <p><strong>{t("invoice.totalDiscount")}:</strong> {invoices?.data?.data?.invoice.order?.total_discount} DMK</p>
          <p><strong>{t("invoice.shippingCost")}:</strong> {invoices?.data?.data?.invoice.order?.shipping_cost} DMK</p>
          <p><strong>{t("invoice.finalTotal")}:</strong> {invoices?.data?.data?.invoice.order?.total_price} DMK</p>
        </div>

        <div className="action mt-5">
          <p onClick={handlePrint}>{t("invoice.printInvoice")}</p>
          <p onClick={handleDownload}>{t("invoice.downloadInvoice")}</p>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
