import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faHeart } from '@fortawesome/free-solid-svg-icons';
import './Favorites.css';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { cart_data } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';

function Cart() {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const cart_data_all = useSelector(state => state.Data.cart_data);
  const loadingCart_data = useSelector(state => state.Data.loadingCart_data);
  console.log(cart_data_all)

  useEffect(() => {
    dispatch(cart_data())

  }, [])


  const listOrders = [
    t('ordersManagement.ID'),
    t('ordersManagement.customer'),
    t('ordersManagement.EMAIL'),
    t('ordersManagement.PHONE NUMBER'),
    t('ordersManagement.total'),
  ];

  const listOrders2 = ['ID', 'IMAGE', 'NAME', 'PRICE', 'CATEGORY', 'QUANTITY'];

  const [showProdcts, setShowProdcts] = useState(null);
  const toggleShowProducts = (id) => setShowProdcts(showProdcts === id ? null : id);


  return (
    <div className="Favorites">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faHeart} /></i>
            <p>{t('Favorites')}</p>
          </div>
        </div>

        <div className='container'>
          {loadingCart_data && <Loader/>}
          <div className='Orders mt-5'>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {cart_data_all?.data?.data?.cart_items.map((order) => (
              <div key={order?.user.user}>
                <div className='Orders_border row' onClick={() => toggleShowProducts(order?.user.id)}>
                  <p className='col-2 text-center'>{order?.user?.id}</p>
                  <p className='col-2 text-center'>{order?.user.name}</p>
                  <p className='col-2 text-center'>{order?.user.email}</p>
                  <p className='col-2 text-center'>{order?.user.phone_number}</p>
                  <p className='col-2 text-center'>{order?.item_count}</p>
                </div>

                <div className={`ProductsWrapper ${showProdcts === order?.user.id ? 'active' : ''}`}>
                  <div className='Orders_border3 row justify-content-around'>
                    {listOrders2.map((item, index) => (
                      <p className='col-1 text-center' key={index}>{item}</p>
                    ))}
                  </div>
                  {order.cart_items?.map((product, index) => (
                    <div className='Orders_border2 row justify-content-around' key={index}>
                      <p className='col-2 text-center'>{product?.product.id}</p>
                      <p className='col-2 text-center'><img src={product?.product.main_image} className='image_Favorites'/></p>
                      <p className='col-2 text-center'>{product?.product.name}</p>
                      <p className='col-2 text-center'>{product?.product.new_price}</p>
                      <p className='col-2 text-center'>{product?.product.category}</p>
                      <p className='col-2 text-center'>{product?.quantity}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;