import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "sidebar": {
          "statistics": "Statistics",
          "orders_management": "Orders Management",
          "customers": "Customers",
          "categories": "Categories",
          "transactions": "Transactions",
          "brand": "Brand",
          "advertisement": "Advertisement",
          "add_product": "Add Product",
          "products_list": "Products List",
          "languages": "Languages",
          "arabic": "Arabic",
          "english": "English",
          "logout": "Logout",
          "home": "Home",
          "chat": "Chat",
          "favorites": "Favorites",
          "about": "About",
          "contact_us": "Contact Us",
          "vendor": "Vendor",
          "cart": "Cart",
          "customer_reports": "Customer Reports",
          "add_coupon": "Add Coupon",
          "inventory_reports": "Inventory Reports",
          "create_coupon": "Create Coupon",
          "create_discount": "Create Discount",
          "add_discount": "Add Discount",
          "product_reports": "Products reports",

        },
    
        "ordersManagement": {
          "recent_orders": "Recent Orders",
          "search_placeholder": "Search by order id",
          "order_id": "ORDER ID",
          "created": "Date and time",
          "customer": "CUSTOMER",
          "total": "TOTAL",
          "profit": "PROFIT",
          "status": "STATUS",
          "ID": "ID",
          "EMAIL": "Email",
          "PHONE NUMBER": "Phone Number",
          "categories": ["Pending", "Confirmed", "Processing", "Shipped", "Delivered", "Cancelled", 'Replaced']
        },
        "customers": {
          "title": "Customers",
          "search_placeholder": "Search by User Email",
          "columns": ["NAME", "PHONE NUMBER", "Date and time", "Email", 'ACTION'],
          "columns2": ["NAME", "PHONE NUMBER", "Date and time", "Email"],
          "vendor": ["NAME", "PHONE NUMBER", "CREATED","ACTION"],
          "categories": ["Pending Approvals", "Approved"]
        },
        "categoires": {
          "title": "Categories",
          "search_placeholder": "Search by Name",
          "add_categoire": "Add Category",
          "recent_orders": "Recent Orders",
          "columns": ["CATEGORY ID", "CATEGORY IMAGE", "CATEGORY NAME", "ACTION"],
          "column": ["CATEGORY ID", "CATEGORY IMAGE", "CATEGORY NAME"],
          "categories": ["Draft", "Active", "Archive"]
        },
        "transactions": {
          "title": "Transactions",
          "search_placeholder": "Search",
          "recent_orders": "Recent Orders",
          "columns": ["ID", "CUSTOMER", "DATE", "PAYMENT METHOD", "TOTAL", "STATUS"],
          "categories": ["Credit/Debit Card", "Cash On Delivery", "Installments"]
        },
        "brand": {
          "title": "Brand",
          "search_placeholder": "Search by Name",
          "add_category": "Add Category",
          "recent_orders": "Recent Orders",
          "columns": ["CATEGORY ID", "CATEGORY IMAGE", "CATEGORY NAME", "ACTION"],
          "categories": ["Draft", "Active", "Archive"]
        },
        "advertisement": {
          "title": "Advertisement",
          "search_placeholder": "Search for advertisement",
          "add_ad": "Add Advertisement",
          "columns": ["image", "name", "Creation date", "Action"]
        },
        "products_list": {
          "product_name": "Product Name",
          "category": "Category",
          "company": "Company",
          "inventory": "Inventory",
          "status": "Status",
          "action": "Action",
          "search": "Search",
          "active": "Active",
          "draft": "Draft",
          "archive": "Archive",
          "products_list": "Products List",
          "discount": "Discount",
          "price": "Price",
          "brand": "Brand",
          "top_selling": "Top Selling Products",
          "least_selling": "Least Selling Products",
          "image": "Image",
          "name": "Name",

        },
        "addProduct": {
          "title": "Add Product",
          "productDescription": "Product Description",
          "media": "Media",
          "dragAndDropSingle": "Drag and drop a file to upload",
          "dragAndDropMultiple": "Drag and drop files to upload",
          "productStatus": "Product Status",
          "discountPrice": "Discount Price",
          "price": "Price",
          "quantity": "Quantity",
          "sku": "SKU",
          "variant": "Variant",
          "category": "Category",
          "tags": "Tags",
          "company": "Company",
          "create": "Create",
          "errorFields": "Please fill all fields!",
          "errorImageUpload": "Please upload only images",
          "productNameEn": "Name Product (En)",
          "productNameAr": "Name Product (Ar)",
          "productDescriptionEn": "Product Description (En)",
          "productDescriptionAr": "Product Description (Ar)",
          "singlePhoto": "Single Photo",
          "deliveryCost": "Add Delivery Cost",
          "deliveryHoursPrice": "Add Hours and Delivery Price",
          "status": "Product Status",
          "colorImages": "Color images",
          "sizes": "Product Sizes",
          "selectedSizes": "Selected Sizes",
          "material": "Material",
          "materialComposition": "Material Composition",
          "warrantyCare": "Warranty & Care",
          "generalSpecifications": "General Specifications",
          "natureComfort": "Nature's Comfort",
          "occasion": "Occasion",
          "model": "Model",
          "edit": "Edit",
          "deliveryHours": "Hours",
          "deliveryPrice": "Price",
          "add": "Add",
        },
    
        "addCategory": {
          "title": "Add Category",
          "singlePhoto": "Single Photo",
          "dragAndDropSingle": "Drag and drop the image here or click to upload",
          "multiplePhotos": "Multiple Photos",
          "dragAndDropMultiple": "Drag and drop the images here or click to upload",
          "taskName": "Task Name",
          "taskType": "Task Type",
          "chooseType": "Choose Type",
          "description": "Description",
          "taskURL": "Task URL",
          "create": "Create",
          "errorSingleImage": "You can only upload one image.",
          "errorMultipleImages": "You can upload up to 5 images.",
          "errorImage": "Please upload an image only.",
          "errorImageUpload": "Please upload at least one image.",
          "errorName": "Please enter the task name.",
          "errorDescription": "Please enter the description.",
          "errorURL": "Please enter the task URL.",
          "errorCategory": "Please select the task type.",
          "errorFields": "Please fill out all fields correctly."
        },
        "statistics": {
          "title": "Statistics",
          "chooseDate": "Choose date to view data",
          "chooseComparisonDate": "Choose comparison date",
          "netProfits": "Net Profits",
          "last7Days": "Last 7 Days",
          "averageOrderValue": "Average order value",
          "grossProfits": "Gross Profits",
          "profitMargins": "Profit margins",
          "durationOfStay": "Duration of stay on site",
          "bounceRate": "Bounce rate",
          "visitorsBought": "Visitors who bought",
          "visitorsDidntBuy": "Visitors who didn't buy",
          "inventoryManagement": "Inventory management",
          "averageDeliveryTime": "Average Delivery Time for Orders",
          "conversionRate": "Conversion Rate",
          "shippedOrdersCount": "Number of Shipped Orders",
          "customerRatings": "Customer Ratings",
          "age": "Age",
          "gender": "Gender",
          "count": "Number of Customers",
          "visitorsToBuyersRate": "Rate of Visitors Who Become Purchasing Customers",
          "usersWhoBought": "Visitors Who Bought",
          "usersWhoDidntBuy": "Visitors Who Did Not Buy",
          "totalProducts": "Total Products",
          "soldProducts": "Sold Products",
          "remainingProducts": "Remaining Products",
          "profits": "Profits",
          "Number_of_customer": "Number of customer",

        },
        

        "invoice": {
          "storeName": "Store Name",
          "shippingAddress": "Shipping Address",
          "orderDate": "Order Date",
          "orderNumber": "Order Number",
          "customerPhone": "Customer Phone",
          "orderStatus": "Order Status",
          "customerNote": "Customer Note",
          "productImage": "Product Image",
          "productName": "Product Name",
          "quantity": "Quantity",
          "price": "Price",
          "discount": "Discount",
          "total": "Total",
          "shippingCost": "Shipping Cost",
          "finalTotal": "Final Total",
          "printInvoice": "Print Invoice",
          "downloadInvoice": "Download Invoice",
          "customerName": "Customer Name",
          "totalQuantity": "Total Quantity",
          "totalDiscount": "Total Discount",
        },
        "coupon": {
          "discount_value": "Discount Value",
          "code": "Code",
          "usage_limit": "Usage Limit",
          "expiry_date": "Expiry Date",
          "action": "Action",
          "name": "Discount Name",
          "discount_type": "Discount Type",
        },
        "actions": {
          "submit": "Submit",
          "add_coupon": "Add Coupon",
          "apply_coupon": "Apply Coupon",
          "apply_discount": "Apply Discount",
          "delete": "Delete"
        },
        "profile": {
          "title": "Profile",
          "company_name": "Company Name",
          "email": "Email",
          "registration_date": "Registration Date",
          "phone": "Phone Number",
          "activity_type": "Activity Type",
          "address": "Address",
          "number_of_products": "Number of Products",
          "category": "Category",
          "drag_and_drop": "Drag And Drop Single",
          "save_changes": "Save Changes"
        },
        "errors": {
          "fill_all_fields": "Please fill in all fields!",
          "select_coupon_and_products": "Please select a coupon and products",
          "select_coupon": "Please select a coupon",
          "select_products": "Please select products",

        },

        "favorites": "Favorites",
        "select_coupon": "Select Coupon",
        "reports": {
          "title": "Customer Reports",
          "new_customers": "New Customers Count",
          "repeat_customers": "Repeat Customers Count",
          "high_value_customers": "High-Value Customers",
          "no_high_value_customers": "No high-value customers found."
        },
        "best_selling_products": "Best Selling Products",
        "featured_products": "Featured Products",
        "remaining_stock": "Remaining Stock",
        "status": {
          "active": "Active (1)",
          "inactive": "Inactive (0)"
        },
        "salesChart": {
          "startDate": "Start Date",
          "fetchData": "Fetch Data",
          "week": "Week",
          "month": "Month",
          "year": "Year",
          "visitors": "Visitors"
        },
        "revenueChart": {
          "selectDate": "Select date to view data",
          "comparisonDate": "Select comparison date",
          "displayData": "Display Data",
          "day": "Day",
          "week": "Week",
          "month": "Month",
          "year": "Year",
          "noData": "No data available to display!",
          "revenue": "Revenue"
        },
      
      }
    },
    ar: {
      translation: {
   "sidebar": {
            "statistics": "إحصائيات",
            "orders_management": "إدارة الطلبات",
            "customers": "العملاء",
            "categories": "الفئات",
            "transactions": "المعاملات",
            "brand": "العلامة التجارية",
            "advertisement": "الإعلانات",
            "add_product": "إضافة منتج",
            "products_list": "قائمة المنتجات",
            "languages": "اللغات",
            "arabic": "العربية",
            "english": "الإنجليزية",
            "logout": "تسجيل الخروج",
            "home": "الصفحة الرئيسية",
            "chat": "الدردشة",
            "favorites": "المفضلة",
            "about": "حول",
            "contact_us": "اتصل بنا",
            "vendor": "البائع",
            "cart": "السلة",
            "customer_reports": "تقارير العملاء",
            "add_coupon": "إضافة كوبون",
            "inventory_reports": "تقارير المخزون",
            "create_coupon":" إنشاء قسيمة",
            "create_discount": "إنشاء خصم",
            "add_discount": "إضافة خصم",
            "product_reports": "تقارير المنتجات",
        },

        "ordersManagement": {
          "recent_orders": "الطلبات الأخيرة",
          "search_placeholder": "ابحث بواسطة معرف الطلب",
          "order_id": "معرف الطلب",
          "created": " التاريخ والوقت",
          "customer": "العميل",
          "total": "الإجمالي",
          "profit": "الربح",
          "status": "الحالة",
          "ID": "الرقم التعريفي",
          "EMAIL": "البريد الإلكتروني",
          "PHONE NUMBER": "رقم الهاتف",
          "categories": ["قيد الانتظار", "تم التأكيد", "قيد المعالجة", "تم الشحن", "تم التوصيل", "تم الإلغاء", 'تم الاستبدال ']
        },
        
        "customers": {
          "title": "العملاء",
          "search_placeholder": "ابحث عن طريق البريد الإلكتروني",
          "columns": ["الاسم", "رقم الهاتف", " التاريخ والوقت", "الاميل", 'الإجراءات'],
          "columns2": ["الاسم", "رقم الهاتف", " التاريخ والوقت", "الاميل"],
          "vendor": ["الاسم", "رقم الهاتف", "تاريخ الإنشاء","الحالة"],
          "categories": ["في انتظار الموافقة", "تمت الموافقة"]
        },
        "categoires": {
          "title": "الفئات",
          "search_placeholder": "ابحث بالاسم",
          "add_categoire": "إضافة فئة",
          "recent_orders": "الطلبات الأخيرة",
          "columns": ["معرف الفئة", "صورة الفئة", "اسم الفئة", "الإجراءات"],
          "column": ["معرف الفئة", "صورة الفئة", "اسم الفئة"],
          "categories": ["مسودة", "نشط", "أرشيف"]
        },
        "transactions": {
          "title": "المعاملات",
          "search_placeholder": "بحث",
          "recent_orders": "الطلبات الأخيرة",
          "columns": ["الرقم", "العميل", "التاريخ", "طريقة الدفع", "الإجمالي", "الحالة"],
          "categories": ["بطاقة ائتمان/خصم", "الدفع عند الاستلام", "أقساط"]
        },
        "brand": {
          "title": "العلامة التجارية",
          "search_placeholder": "ابحث بالاسم",
          "add_category": "إضافة فئة",
          "recent_orders": "الطلبات الأخيرة",
          "columns": ["معرف الفئة", "صورة الفئة", "اسم الفئة", "الإجراءات"],
          "categories": ["مسودة", "نشط", "أرشيف"]
        },
        "advertisement": {
          "title": "الإعلانات",
          "search_placeholder": "ابحث عن إعلان",
          "add_ad": "إضافة إعلان",
          "columns": ["الصورة", "الاسم", "تاريخ الإنشاء", "الإجراء"]
        },
        "products_list": {
          "product_name": "اسم المنتج",
          "category": "الفئة",
          "company": "الشركة",
          "inventory": "المخزون",
          "status": "الحالة",
          "action": "الإجراء",
          "search": "بحث",
          "active": "نشط",
          "draft": "مسودة",
          "archive": "أرشيف",
          "products_list": "قائمة المنتجات",
          "discount": "الخصم",
          "price": "السعر",
          "brand": "العلامة التجارية",
          "top_selling": "المنتجات الأكثر مبيعاً",
          "least_selling": "المنتجات الأقل مبيعاً",
          "image": "صورة",
          "name": "اسم",
  
        },
    
        "addProduct": {
          "title": "إضافة منتج",
          "productDescription": "وصف المنتج",
          "media": "الوسائط",
          "dragAndDropSingle": "اسحب وأسقط ملفًا لتحميله",
          "dragAndDropMultiple": "اسحب وأسقط الملفات لتحميلها",
          "discountPrice": "سعر الخصم",
          "price": "السعر",
          "quantity": "الكمية",
          "sku": "SKU",
          "variant": "النسخة",
          "productStatus": "حالة المنتج",
          "category": "الفئة",
          "tags": "العلامات",
          "company": "الشركة",
          "create": "إنشاء",
          "errorFields": "يجب ملء جميع الحقول!",
          "productNameEn": "اسم المنتج (En)",
          "productNameAr": "اسم المنتج (Ar)",
          "productDescriptionEn": "وصف المنتج (En)",
          "productDescriptionAr": "وصف المنتج (Ar)",
          "singlePhoto": "صورة واحدة",
          "deliveryCost": "أضف تكلفة التوصيل",
          "deliveryHoursPrice": "أضف عدد الساعات وسعر التوصيل",
          "status": "حالة المنتج",
          "colorImages": "صور الألوان",
          "sizes": "مقاسات المنتج",
          "selectedSizes": "المقاسات المختارة",
          "material": "المواد",
          "materialComposition": "تركيبة المواد",
          "warrantyCare": "الضمان والعناية",
          "generalSpecifications": "المواصفات العامة",
          "natureComfort": "راحة الطبيعة",
          "occasion": "المناسبة",
          "model": "الموديل",
          "edit": "تعديل",
          "deliveryHours": "عدد الساعات",
          "deliveryPrice": "السعر",
          "add": "إضافة"
        },
        "addCategory": {
          "title": "إضافة فئة",
          "singlePhoto": "صورة فردية",
          "dragAndDropSingle": "قم بسحب وإفلات الصورة هنا أو انقر للتحميل",
          "multiplePhotos": "صور متعددة",
          "dragAndDropMultiple": "قم بسحب وإفلات الصور هنا أو انقر للتحميل",
          "taskName": "اسم المهمة",
          "taskType": "نوع المهمة",
          "chooseType": "اختر النوع",
          "description": "الوصف",
          "taskURL": "رابط المهمة",
          "create": "إنشاء",
          "errorSingleImage": "يمكنك تحميل صورة واحدة فقط.",
          "errorMultipleImages": "يمكنك تحميل ما يصل إلى 5 صور.",
          "errorImage": "يرجى تحميل صورة فقط.",
          "errorImageUpload": "يرجى تحميل صورة واحدة على الأقل.",
          "errorName": "يرجى إدخال اسم المهمة.",
          "errorDescription": "يرجى إدخال الوصف.",
          "errorURL": "يرجى إدخال رابط المهمة.",
          "errorCategory": "يرجى اختيار نوع المهمة.",
          "errorFields": "يرجى تعبئة جميع الحقول بشكل صحيح."

        },
        "statistics": {
          "title": "الإحصائيات",
          "chooseDate": "اختر التاريخ لعرض البيانات",
          "chooseComparisonDate": "اختر تاريخ المقارنة",
          "netProfits": "صافي الأرباح",
          "last7Days": "آخر 7 أيام",
          "averageOrderValue": "متوسط قيمة الطلب",
          "grossProfits": "إجمالي الأرباح",
          "profitMargins": "هامش الربح",
          "durationOfStay": "مدة البقاء على الموقع",
          "bounceRate": "معدل الارتداد",
          "visitorsBought": "الزوار الذين اشتروا",
          "visitorsDidntBuy": "الزوار الذين لم يشتروا",
          "inventoryManagement": "إدارة المخزون",
          "averageDeliveryTime": "متوسط الوقت المستغرق لتسليم الطلبات",
          "conversionRate": "معدل التحول",
          "shippedOrdersCount": "عدد الطلبات المشحونة",
          "customerRatings": "تصنيفات العملاء",
          "age": "العمر",
          "gender": "النوع",
          "count": "عدد العملاء",
          "visitorsToBuyersRate": "نسبة الزوار الذين يتحولون إلى عملاء مشترين",
          "usersWhoBought": "الزوار الذين اشتروا",
          "usersWhoDidntBuy": "الزوار الذين لم يشتروا",
          "totalProducts": "إجمالي المنتجات",
          "soldProducts": "المنتجات المباعة",
          "remainingProducts": "المنتجات المتبقية",
          "profits": "الأرباح",
          "Number_of_customer": "عدد العملاء"
        },
        "profile": {
          "title": "الملف الشخصي",
          "company_name": "اسم الشركة",
          "email": "البريد الإلكتروني",
          "registration_date": "تاريخ التسجيل",
          "phone": "رقم الهاتف",
          "activity_type": "نوع النشاط",
          "address": "العنوان",
          "number_of_products": "عدد المنتجات",
          "category": "الفئة",
          "drag_and_drop": "اسحب وأسقط لتحميل ملف واحد",
          "save_changes": "حفظ التعديلات"
        },
        "coupon": {
          "name": "اسم الخصم",
          "discount_value": "قيمة الخصم",
          "usage_limit": "حد الاستخدام",
          "creation_date": "تاريخ الإنشاء",
          "action": "الإجراء",
          "discount_type": "نوع الخصم"
        },
        "status": {
          "active": "نشط (1)",
          "inactive": "غير نشط (0)"
        },
        "invoice": {
          "storeName": "اسم المتجر",
          "shippingAddress": "عنوان الشحن",
          "orderDate": "تاريخ الطلب",
          "orderNumber": "رقم الطلب",
          "customerPhone": "هاتف العميل",
          "orderStatus": "حالة الطلب",
          "customerNote": "ملاحظات العميل",
          "productImage": "صورة المنتج",
          "productName": "اسم المنتج",
          "quantity": "الكمية",
          "price": "السعر",
          "discount": "الخصم",
          "total": "المجموع",
          "shippingCost": "سعر التوصيل",
          "finalTotal": "الإجمالي النهائي",
          "printInvoice": "طباعة الفاتورة",
          "downloadInvoice": "تنزيل الفاتورة",
          "customerName": "اسم العميل",
          "totalQuantity": "عدد المنتجات",
          "totalDiscount": "سعر الخصم",
        },
        "actions": {
          "submit": "إرسال",
          "add_coupon": "إضافة كوبون",
          "apply_coupon": "تطبيق الكوبون",
          "apply_discount": "تطبيق الخصم",
          "delete": "حذف"

        },
        "errors": {
          "fill_all_fields": "من فضلك املأ جميع الحقول!",
          "select_coupon_and_products": "من فضلك اختر الكوبون والمنتجات",
          "select_coupon": "من فضلك اختر الكوبون",
          "select_products": "من فضلك اختر المنتجات",

        },
        "favorites": "المفضلة",
        "select_coupon": "اختر كوبون",
        "reports": {
          "title": "تقارير العملاء",
          "new_customers": "عدد العملاء الجدد",
          "repeat_customers": "عدد العملاء المكررين",
          "high_value_customers": "العملاء الأكثر قيمة",
          "no_high_value_customers": "لا يوجد عملاء ذوي قيمة عالية."
        },
        "best_selling_products": "المنتجات الأكثر مبيعاً",
        "featured_products": "المنتجات النافذة",
        "remaining_stock": "المخزون المتبقي",

        "salesChart": {
          "startDate": "تاريخ البدء",
          "fetchData": "جلب البيانات",
          "week": "أسبوع",
          "month": "شهر",
          "year": "سنة",
          "visitors": "الزوار"
        },
        "revenueChart": {
          "selectDate": "اختر التاريخ لعرض البيانات",
          "comparisonDate": "اختر تاريخ المقارنة",
          "displayData": "عرض البيانات",
          "day": "اليوم",
          "week": "الأسبوع",
          "month": "الشهر",
          "year": "السنة",
          "noData": "لا توجد بيانات لعرضها!",
          "revenue": "الإيرادات"
        }
    
    
        
      }
    }
  },
  lng: "en", // اللغة الافتراضية
  fallbackLng: "en",

  interpolation: {
    escapeValue: false // مش محتاجه مع React
  }

});

export default i18n;