
import './Header.css';
import image4 from '../images/IMG-20240723-WA0024.jpg';
import img13 from "../images/5565381.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMagnifyingGlass, faHeart, faUser, faCartPlus, faBell} from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Register from "../Register/Register"; 
import i18n from "../i18n";
import logo from '../images/Group.png';
import img1 from '../images/Asset 19.png';


function Header(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const gotoProfile = () => {
        navigate('/Profile');
    };



    // languages
    const languages = ['En', 'Ar'];

    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

    useEffect(() => {
        i18n.changeLanguage(language);
    
        // تعديل الـ direction والـ text-align بناءً على اللغة
        const body = document.body;
        if (language === 'ar') {
            body.style.direction = 'rtl';
            body.style.textAlign = 'right';
        } else {
            body.style.direction = 'ltr';
            body.style.textAlign = 'left';
        }
    }, [language]);




    const [icon, setIcon] = useState(false);
    const [text, setText] = useState(localStorage.getItem('text') || 'EN');
    function handelClick() {
        setIcon(!icon);
    }

    const [iconCantry, setIconCantry] = useState(false);
    const [imgCantry, setImgCantry] = useState(image4);
    function Clickcantry() {
        setIconCantry(!iconCantry);
    }

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
        
        const newText = lang === 'ar' ? 'AR' : 'EN'; // هنا بنحدد النص بناءً على اللغة
        setText(newText);
        localStorage.setItem('text', newText); // نحفظ النص الجديد في localStorage
    
        window.location.reload(); // عمل ريلود للصفحة بعد تغيير اللغة
    };

    const [showRegister, setShowRegister] = useState(false);
  
    // استرجاع البيانات من الـ localStorage
    const getLocalStorage = window.localStorage.getItem('UserInfo');
    const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
    const token = valueLocalStorage?.data?.token || null;
  
  
    // دالة التسجيل
    const handleRegistrationSuccess = () => {
      setShowRegister(false);
      window.location.reload(); // إعادة تحميل الصفحة بعد التسجيل
    };
  
  
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState(["إشعار 1", "إشعار 2", "إشعار 3"]); // افتراض وجود إشعارات

    // دالة للتحكم في إظهار القائمة المنسدلة
    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };
    const [showadmin, setShowadmin] = useState(valueLocalStorage?.data?.user?.role);

    const [companyName, setCompanyName] = useState(valueLocalStorage?.data?.[0]?.vendor_info?.company || '');
    const [singleImage, setSingleImage] = useState(valueLocalStorage?.data?.[0]?.user_image || null);




    return (
        <div className="Header">
            <div className={`naveBar `}>

                <div className={`inpute_search`}>
                    <i className='magnifier '><FontAwesomeIcon icon={faMagnifyingGlass} /></i> 
                    <input type="text" name="search" readOnly/>
                </div>

                <div className='group_profile'>
                    <div className='img_name'>
                {showadmin !== 'admin' &&

                        <div>
                            <img src={singleImage} alt='User' onClick={gotoProfile}/>
                            <span>{companyName}</span>
                        </div>
                }

                    </div>
                    <div className={`notifications-dropdown ${showNotifications ? 'show' : ''}`}>
                        <ul>
                            {notifications.length > 0 ? (
                                notifications.map((notif, index) => <li key={index}>{notif}</li>)
                            ) : (
                                <li>لا توجد إشعارات</li>
                            )}
                        </ul>
                    </div>

                    <div className="language ">
                        <div className="slectList">
                            <p>{text}</p>
                            <i onClick={handelClick} className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                        </div>

                        {icon &&
                            <div className="lest">
                                {languages.map((e, i) => 
                                    <p key={i} onClick={() => (setText(e), setIcon(!icon), handleLanguageChange(e.toLowerCase()))}>{e}</p>
                                )}
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Header;