import './Brand.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEdit, faPlus, faTrademark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { display_brands, delete_brands } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';
function Brand() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(display_brands())

  }, [])

  const loadingDisplay_brands = useSelector(state => state.Data.loadingDisplay_brands);
  const loadingDelete_brands = useSelector(state => state.Data.loadingDelete_brands);
  const display_brand = useSelector(state => state.Data.display_brands);
  console.log(display_brand)


  useEffect(() => {
    dispatch(display_brands())

  }, [])


  const gotoCategoiresDetial = () => {
    navigate('/CategoiresDetial');
  };

  const AddCompany = () => {
    navigate('/AddCompany');
  };


  const listOrders = t('brand.columns', { returnObjects: true });


  const handleDeleteBrands = (id) => {
    dispatch(delete_brands(id));
  };

  return (
    <div className="Brand">
      <Header />
      <div className='container-fluid'>
        {loadingDisplay_brands && <Loader />}
        {loadingDelete_brands && <Loader />}
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faTrademark} /></i>
            <p>{t('brand.title')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh by Name'>
              <input placeholder={t('brand.search_placeholder')} />
              <div className='AddCat' onClick={AddCompany}>
                <span><FontAwesomeIcon icon={faPlus} /></span>
                <span>{t('brand.add_category')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Recent'>{t('brand.recent_orders')}</div>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {display_brand?.data?.data?.brands.map((brand, index) => (
              <div key={brand.id}>
                <div className='Orders_border row justify-content-around'>
                  <p className='col-2 text-center' onClick={gotoCategoiresDetial}>{brand.id}</p>
                  <p className='col-2 text-center' onClick={gotoCategoiresDetial}><img src={brand.logo} alt={index} /></p>
                  <p className='col-2 text-center' onClick={gotoCategoiresDetial}>{brand.name}</p>
                  <p className='col-2 text-center'>
                    <span className='span_icon'><FontAwesomeIcon icon={faEdit} /></span>
                    <span 
                      className='span_icon2' 
                      onClick={() => handleDeleteBrands(brand.id)}  // تمرير الـ id للباك إند
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand;
