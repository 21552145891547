import './DataCustomers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import img1 from './assets/compiled/jpg/4.jpg'
import { useTranslation } from 'react-i18next';
import Header from "./Header/Header";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { invoice } from "./Redux/API";



function DataCustomers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productFromState = useSelector(state => state?.products);
  console.log(productFromState)


  const gotoInvoice = (id) => {
    navigate('/Invoice');  // التنقل إلى صفحة الفاتورة
    console.log(id);  // طباعة id للتأكد
    dispatch(invoice(id));  // إرسال الـ id إلى الـ backend باستخدام dispatch
};


  const listOrders = [
    t('ordersManagement.order_id'),
    t('ordersManagement.created'),
    t('ordersManagement.customer'),
    t('ordersManagement.total'),
    t('ordersManagement.profit'),
  ];

  const listOrders2 = ['ID', 'IMAGE', 'NAME', 'PRICE', 'QTY', 'DISCOUNT'];

  const [showProdcts, setShowProdcts] = useState(null);

  const toggleShowProducts = (id) => {
    setShowProdcts(showProdcts === id ? null : id);
  };

  return (
    <div className="DataCustomers">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i><FontAwesomeIcon icon={faCartPlus} /></i>
            <p>Orders Management</p>
          </div>
        </div>

        <div className='container'>
          <div className='row align-items-center'>
            <div className='Pending align-items-center'>

                <div className=' box1 col-4 g-0'>
                  <div>
                    <img src={productFromState?.product?.user_image}/>
                    <p>{productFromState?.product?.first_name}</p>
                  </div>
                    <p className='custmr_Points mt-3'>(Points {productFromState?.product?.points_earned_this_month})</p>
                </div>

                <div className='box2 col-4 g-0'>
                  <p className='title1'>PERSONAL INFORMATION</p>
                  <div className='cont'>
                    <div>
                      <p>Contact</p>
                      <p>Gender</p>
                      <p>Status</p>
                    </div>
                    <div>
                      <p>{productFromState?.product?.phone_number}</p>
                      <p>{productFromState?.product?.gender}</p>
                      <p>{productFromState?.product?.status}</p>
                    </div>
                  </div>
                </div>

                <div className='box3 col-4 g-0'>
                  <p className='title2'>SHIPPING</p>
                  <div className='cont2'>
                    <div>
                      <span>{productFromState?.product?.total_orders}</span>
                      <p>Total Orders</p>
                    </div>
                    <div>
                      <span>{productFromState?.product?.completed_orders}</span>
                      <p>Completed</p>
                    </div>
                    <div>
                      <span>{productFromState?.product?.cancelled_orders}</span>
                      <p>Cancelled</p>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Recent'>{t('ordersManagement.recent_orders')}</div>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>



              {productFromState?.product?.orders.map((order) => (
                <div key={order.order_id}>
                  <div className='Orders_border row justify-content-around'>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.order_code}</p>
                    <i className='icon_Invoice' onClick={() => gotoInvoice(order.order_id)}><FontAwesomeIcon icon={faFileInvoice} /></i>
                    

                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.order_date}</p>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.user_name}</p>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.num_of_items}</p>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.total_price}</p>
                  </div>

                  <div className={`ProductsWrapper ${showProdcts === order.order_id ? 'active' : ''}`}>
                    <div className='Orders_border3 row justify-content-around'>
                      {listOrders2.map((item, index) => (
                        <p className='col-2 text-center' key={index}>{item}</p>
                      ))}
                    </div>
                    
                    {order?.order_items.map((product, index) => (
                      <div className='Orders_border2 row justify-content-around' key={index}>
                        <p className='col-2 text-center'>{product.id}</p>
                        <p className='col-2 text-center '><img src={product.product_image} className='product_image'/></p>
                        <p className='col-2 text-center'>{product.product}</p>
                        <p className='col-2 text-center'>{product.product_price}</p>
                        <p className='col-2 text-center'>{product.quantity}</p>
                        <p className='col-2 text-center'>{product.discount}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}


          </div>
        </div>
      </div>
    </div>
  );
}

export default DataCustomers;