import './ProductsList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faListAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searsh_product, product, delete_product, product_status, archive_status, update_status } from "./Redux/API";
import Loader from './Loader';
import { useEffect } from 'react';
import { EditProduct } from "./Redux/HomeSlice";


function ProductsList() {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  
    const gotoedit_Product = (item) => {
      dispatch(EditProduct(item));
      navigate('/AddProduct');
    };





  const products = useSelector(state => state.Data.product);
  const loadingProduct = useSelector(state => state.Data.loadingProduct);
  const loadingDelete_product = useSelector(state => state.Data.loadingDelete_product);

  const product_statu = useSelector(state => state.Data.product_status);
  const loadingPoduct_status = useSelector(state => state.Data.loadingProduct);


  const archive_statu = useSelector(state => state.Data.archive_status);
  const loadingArchive_status = useSelector(state => state.Data.loadingArchive_status);

  const searsh_products = useSelector(state => state.Data.searsh_product);
  const loadingSearsh_product = useSelector(state => state.Data.loadingSearsh_product);

  const loadingUpdate_status = useSelector(state => state.Data.loadingUpdate_status);




  
  // **حالة البحث**
  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshBroduct(e.target.value); 
  };

  useEffect(() => {
      dispatch(searsh_product(searshBroduct)); // استدعاء البحث
  }, [searshBroduct, dispatch]);



  useEffect(() => {
      dispatch(product()); 
  }, [dispatch, loadingDelete_product, loadingUpdate_status]);

  useEffect(() => {
      dispatch(product_status()); 
  }, [dispatch]);

  const handleDeleteProduct = (id) => {
    dispatch(delete_product(id));
  };

  const [selectedStatus, setSelectedStatus] = useState('pending approval'); 

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    dispatch(archive_status( status ));
  };

  useEffect(() => {
    dispatch(archive_status(selectedStatus));
}, [ dispatch, loadingPoduct_status]);



const listOrders = [
  t('products_list.product_name'),
  t('products_list.category'),
  t('products_list.price'),     
  t('products_list.brand'),     
  t('products_list.status'),
  t('products_list.action')
];

  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});


  const handleCategoryClick = (id) => {
    setOpenCategory(openCategory === id ? null : id);
  };

  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
    // إرسال البيانات المحدّثة للباك إند
    dispatch(update_status({
      id: id, 
      status: category
    }));
  };

  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faListAlt} /></i>
            <p>{t('products_list.products_list')}</p>
          </div>
        </div>

        <div className='container mt-4'>
          <div className='row'>
            <div className='Pending'>

              {product_statu?.data?.data?.product_archive_status.map((e, i) => (
                <p 
                  key={i} 
                  onClick={() =>{handleStatusClick(e)}}
                  className={selectedStatus === e ? 'selectedMethod' : ''} 
                  >
                  {e}
                </p>
              ))}

            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh col-5'>
            <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} placeholder={t('products_list.search')} />

            </div>
          </div>
        </div>

        <div className='container'>
          {loadingProduct && <Loader />}
          {loadingDelete_product && <Loader />}
          {loadingUpdate_status && <Loader />}
          {loadingArchive_status && <Loader />}
          {loadingSearsh_product && <Loader />}
          <div className='Orders'>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center mb-3' key={index}>{e}</p>
              ))}
            </div>
            {searshBroduct
            ? (
                // عرض نتائج البحث
                searsh_products?.data?.data.map((item) => (
                  <div key={item.id}>
                    <div className='Orders_border row align-items-center'>
                      <p className='col-2 text-start d-flex align-items-center'>
                        <img src={item.main_image} alt={item.id}/>
                        <span>{item.name}</span>
                      </p>
                      <p className='col-2 text-center'>{item.category}</p>
                      <p className='col-2 text-center'>{item.new_price}</p>
                      <p className='col-2 text-center'>{item.brand}</p>
                      <p className='col-2 text-center'>
                        <div className="box_add" onClick={() => handleCategoryClick(item.id)}>
                          <div className="heade">
                            <span>{selectedCategory[item.id] || item.archive_status}</span>
                            <i><FontAwesomeIcon icon={faChevronRight} /></i>
                          </div>
                          {openCategory === item.id &&
                            <div className="cat2">
                              {product_statu?.data?.data?.product_archive_status.map((e, i) =>
                                <p key={i} onClick={() => handleSelectCategory(item.id, e)}>{e}</p>
                              )}
                            </div>
                          }
                        </div>
                      </p>
                      <p className='col-2 text-center'>
                        <span 
                          className='span_icon2' 
                          onClick={() => handleDeleteProduct(item.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </p>
                    </div>
                  </div>
                ))
              )
            : (
                // عرض المنتجات المؤرشفة (الأساسية) عندما يكون البحث فارغاً
                archive_statu?.data?.data.map((item) => (
                  <div key={item.id}>
                    <div className='Orders_border row align-items-center'>
                      <p className='col-2 text-start d-flex align-items-center'>
                        <img src={item.main_image} alt={item.id}/>
                        <span>{item.name}</span>
                      </p>
                      <p className='col-2 text-center'>{item.category}</p>
                      <p className='col-2 text-center'>{item.new_price}</p>
                      <p className='col-2 text-center'>{item.brand}</p>
                      <p className='col-2 text-center'>
                        <div className="box_add" onClick={() => handleCategoryClick(item.id)}>
                          <div className="heade">
                            <span>{selectedCategory[item.id] || item.archive_status}</span>
                            <i><FontAwesomeIcon icon={faChevronRight} /></i>
                          </div>
                          {openCategory === item.id &&
                            <div className="cat2">
                              {product_statu?.data?.data?.product_archive_status.map((e, i) =>
                                <p key={i} onClick={() => handleSelectCategory(item.id, e)}>{e}</p>
                              )}
                            </div>
                          }
                        </div>
                      </p>
                      <p className='col-2 text-center'>
                        <span 
                          className='span_icon2' 
                          onClick={() => handleDeleteProduct(item.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </p>
                    </div>
                  </div>
                ))
              )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsList;





