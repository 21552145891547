import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import './OrdersManagement.css';
import './VendorOrderManagement.css';
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { order_items, orders_status, update_orders, invoice, } from "./Redux/API";
import Loader from './Loader';

function VendorOrderManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingOrder_items = useSelector(state => state.Data.loadingOrder_items);
  const order_item = useSelector(state => state.Data.order_items);
  const loadingOrders_status = useSelector(state => state.Data.loadingOrders_status);
  const orders_statu = useSelector(state => state.Data.orders_status);
  const loadingUpdate_orders = useSelector(state => state.Data.loadingUpdate_orders);
  const update_order = useSelector(state => state.Data.update_orders);

  console.log(orders_statu)


  useEffect(() => {
    dispatch(order_items());
  }, [dispatch, loadingUpdate_orders]);


  const [selectedStatus, setSelectedStatus] = useState('pending'); 

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    dispatch(orders_status( status ));
  };

  useEffect(() => {
    dispatch(orders_status(selectedStatus));
}, [ dispatch, loadingOrder_items] );

  console.log('8888888888888888888888888888888888888888888888888888')

  
  const gotoInvoice = (id) => {
    navigate('/Invoice');  // التنقل إلى صفحة الفاتورة
    console.log(id);  // طباعة id للتأكد
    dispatch(invoice(id));  // إرسال الـ id إلى الـ backend باستخدام dispatch
};



  const listOrders = [
    t('ordersManagement.order_id'),
    t('ordersManagement.created'),
    t('ordersManagement.customer'),
    t('ordersManagement.total'),
    t('ordersManagement.profit'),
    t('ordersManagement.status')
  ];

  const listOrders2 = ['ID', 'IMAGE', 'NAME', 'PRICE', 'QTY', 'DISCOUNT'];

  const [showProdcts, setShowProdcts] = useState(null);
  const toggleShowProducts = (id) => setShowProdcts(showProdcts === id ? null : id);

  const categories = t('ordersManagement.categories', { returnObjects: true });
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
    // إرسال البيانات المحدّثة للباك إند
    dispatch(update_orders({
      id: id, 
      status: category
    }));
  };

  const handleCategoryClick = (id) => setOpenCategory(openCategory === id ? null : id);

  return (
    <div className="OrdersManagement">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faCartPlus} /></i>
            <p>{t('sidebar.orders_management')}</p>
          </div>
        </div>


        <div className='container'>
          <div className='row'>
            <div className='Pending'>
              {order_item?.data?.data?.order_status_data.map((e, i) => (
                <p 
                  key={i} 
                  onClick={() => handleStatusClick(e)}
                  className={selectedStatus === e ? 'selectedMethod' : ''} 
                >
                  {e}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('ordersManagement.search_placeholder')} />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            {loadingOrder_items && <Loader />}
            {loadingOrders_status && <Loader />}
            {loadingUpdate_orders && <Loader />}
            <div className='Recent'>{t('ordersManagement.recent_orders')}</div>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

              {orders_statu?.data?.data?.orders.map((order) => (
                <div key={order.order_id}>
                  <div className='Orders_border row'>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.order_code}</p>
                    <i className='icon_Invoice' onClick={() => gotoInvoice(order.order_id)}><FontAwesomeIcon icon={faFileInvoice} /></i>

                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.order_date}</p>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.user_name}</p>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.num_of_items}</p>
                    <p className='col-2 text-center' onClick={() => toggleShowProducts(order.order_id)}>{order.total_price}</p>
                    <p className='col-2 text-center'>
                      <div className="box_add" onClick={() => handleCategoryClick(order.order_id)}>
                        <div className="heade">
                          <span>{selectedCategory[order.order_id] || order.order_status}</span>
                          <i><FontAwesomeIcon icon={faChevronRight} /></i>
                        </div>
                        {openCategory === order.order_id &&
                          <div className="cat2">
                            {order_item?.data?.data?.order_status_data.map((e, i) =>
                              <p key={i} onClick={() => handleSelectCategory(order.order_id, e)}>{e}</p>
                            )}
                          </div>
                        }
                      </div>
                    </p>
                  </div>

                  <div className={`ProductsWrapper ${showProdcts === order.order_id ? 'active' : ''}`}>
                    <div className='Orders_border3 row justify-content-around'>
                      {listOrders2.map((item, index) => (
                        <p className='col-2 text-center' key={index}>{item}</p>
                      ))}
                    </div>
                    
                    {order?.order_items.map((product, index) => (
                      <div className='Orders_border2 row justify-content-around' key={index}>
                        <p className='col-2 text-center'>{product.id}</p>
                        <p className='col-2 text-center '><img src={product.product_image} className='product_image'/></p>
                        <p className='col-2 text-center'>{product.product}</p>
                        <p className='col-2 text-center'>{product.product_price}</p>
                        <p className='col-2 text-center'>{product.quantity}</p>
                        <p className='col-2 text-center'>{product.discount}</p>
                      </div>
                    ))}
                  </div>

                </div>
              ))}


          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorOrderManagement;
