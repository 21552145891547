import React, { useState, useCallback } from "react";
import './AddProduct.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCheck, faChevronRight, faCloudUpload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { category, product_status, post_product,  edit_product} from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';
import { useTranslation } from "react-i18next";
import Header from "./Header/Header";


function AddProduct() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const productD = useSelector(state => state?.products.productD);
    console.log(productD)
    useEffect(() => {
        if (productD) {
          setTitleEn(productD?.name_en || ''); 
          setTitleAr(productD?.name_ar || ''); 
          setDescriptionEn(productD?.description_en || ''); 
          setDescriptionAr(productD?.description_ar || ''); 
          setPrice(productD?.new_price || ''); 
          setQuantity(productD?.remaining_quantity || ''); 
          setPriceInput(productD?.delivery_price || ''); 
        //   setSku(productD.sku || ''); // SKU إن وجد
          setSingleImage(productD?.main_image || []); 
        //   setMultipleImagesColor(productD?.colors || []);
        //   setMultipleImages(productD?.images || []);
        //   setMultipleImages();
          setTypeCategory(productD?.category || ''); 
          setTypeSubCategory(productD?.status || ''); 
          setSizes(productD?.sizes?.map(t => t.size) || []); // الأحجام
        //   setSizes(productD?.sizes || []); // الأحجام
          setCustomSizes([{ size: '' }]); // الأحجام المخصصة (إذا لزم الأمر)
          
          // الحقول الإضافية
          setMaterial(productD?.product_varients[0].material || ''); // المادة
          setMaterialComposition(productD?.product_varients[0].material_composition || ''); // المادة
          setWarrantyCare(productD?.product_varients[0].care_instructions || ''); // المادة
          setGeneralSpecifications(productD?.product_varients[0].material_composition || ''); // المادة
          setNatureComfort(productD?.product_varients[0].nature_comfort || ''); // المادة
          setOccasion(productD?.product_varients[0].occasion || ''); // المادة
          setModel(productD.model || ''); // الموديل
          // حقول الشحن
          setTimePriceInputs(
            productD.shippings?.map(shipping => ({ hours: shipping.key, price: shipping.value })) || 
            [{ hours: '', price: '' }]
          );
    
          // إذا كان هناك بيانات إضافية مثل المواصفات العامة والراحة والطبيعة والموعد المناسب
        }
      }, [productD]);



    //   const [multipleImages, setMultipleImages] = useState([]);
      const [multipleImagesColor, setMultipleImagesColor] = useState([]);
      
      const [multipleImages, setMultipleImages] = useState([]);

      // الفنكشن اللي بتحوّل URL إلى File
      const urlToFile = async (url, filename, mimeType) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          return new File([blob], filename, { type: mimeType });
        } catch (error) {
          console.error("Error fetching the file: ", error);
          return null; // عشان تتجنب مشاكل لو فشل الـ fetch
        }
      };
    
      // الفنكشن اللي بتحوّل Array من URLs إلى Array من Files
      const convertUrlsToFiles = async (urlsArray) => {
        const files = await Promise.all(
          urlsArray.map((url, index) => {
            const mimeType = url.endsWith('.png') ? 'image/png' : 'image/jpeg';
            return urlToFile(url, `image_${index + 1}`, mimeType);
          })
        );
        return files.filter(file => file !== null); // نتجنب null files
      };
    
      // لما الكومبوننت يتحمل، بنحمل الصور ونخزنهم في `multipleImages`
      useEffect(() => {
        if (productD?.images) {
          const urlsArray = productD.images.map(image => image.image);
          const loadFiles = async () => {
            try {
              const files = await convertUrlsToFiles(urlsArray);
              setMultipleImages(files);
            } catch (error) {
              console.error("Error loading files: ", error);

            }
          };
    
          loadFiles(); // تأكدنا من وضع `await` لتحميل الملفات
        }
      }, [productD]); // نضيف `productD` للـ dependency array
    



    
console.log(multipleImages)
console.log(productD?.images?.map(image => image.image))
    // // دالة لتحويل الروابط الخاصة بالألوان إلى ملفات
    // async function urlToFiles(url, filename, mimeType) {
    //     try {
    //         const response = await fetch(url);
    //         if (!response.ok) {
    //             throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
    //         }
    //         const blob = await response.blob();
    //         return new File([blob], filename, { type: mimeType });
    //     } catch (error) {
    //         console.error(`Error fetching the file from ${url}:`, error);
    //         throw error;
    //     }
    // }
    
    // async function convertUrlsToFiless(urls) {
    //     if (!urls || urls.length === 0) return []; // تحقق من وجود الروابط قبل التحويل
    
    //     const files = await Promise.all(
    //         urls.map(async (url, index) => {
    //             let mimeType = 'image/png'; // الافتراضي هو PNG
    //             if (url.endsWith('.jpg') || url.endsWith('.jpeg')) {
    //                 mimeType = 'image/jpeg';
    //             }
    
    //             const filename = `image_${index + 1}.${mimeType.split('/')[1]}`; // توليد اسم ملف
    //             return await urlToFiles(url, filename, mimeType);
    //         })
    //     );
    //     return files;
    // }
    
    // // الروابط الخاصة بصور الألوان
    // const colorsImageUrls = productD?.colors?.map(color => color.image) || [];
    
    // // شرط لمنع التكرار: تحقق إذا كانت الصور قد تغيرت بالفعل
    // if (colorsImageUrls.length > 0 && multipleImagesColor.length === 0) {
    //     convertUrlsToFiless(colorsImageUrls).then(files => {
    //         setMultipleImagesColor(files); // تعيين الملفات في setMultipleImagesColor
    //     }).catch(error => {
    //         console.error('Error converting color URLs to files:', error);
    //     });
    // }




    const loadingCategory = useSelector(state => state.Data.loadingCategory);

    const product_statu = useSelector(state => state.Data.product_status);
    const loadingPoduct_status = useSelector(state => state.Data.loadingProduct);
    const loadingEdit_product = useSelector(state => state.Data.loadingEdit_product);

    const post_products = useSelector(state => state.Data.post_product);
    const loadingPost_product = useSelector(state => state.Data.loadingPost_product);
    console.log(product_statu)

    useEffect(() => {
        dispatch(product_status()); 
    }, [dispatch]);

    const categorys = useSelector(state => state.Data.category);
    console.log(categorys?.data?.data)

    useEffect(() => {
      dispatch(category())
    }, [dispatch])

 const [titleEn, setTitleEn] = useState('');
  const [titleAr, setTitleAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [priceInput, setPriceInput] = useState('');
  const [timePriceInputs, setTimePriceInputs] = useState([{ hours: '', price: '' }]);
  const [singleImage, setSingleImage] = useState([]);
//   const [multipleImages, setMultipleImages] = useState([]);
//   const [multipleImagesColor, setMultipleImagesColor] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [customSizes, setCustomSizes] = useState([{ size: '' }]);
  const [typeCategory, setTypeCategory] = useState('');
  const [typeCategory_id, setTypeCategory_id] = useState(null);
  const [typeSubCategory, setTypeSubCategory] = useState('');

  // إضافة الحقول الجديدة
  const [material, setMaterial] = useState('');
  const [materialComposition, setMaterialComposition] = useState('');
  const [warrantyCare, setWarrantyCare] = useState('');
  const [generalSpecifications, setGeneralSpecifications] = useState('');
  const [natureComfort, setNatureComfort] = useState('');
  const [occasion, setOccasion] = useState('');
  const [model, setModel] = useState('');

  const [showSize, setShowSize] = useState(true);



    const [showPopup, setShowPopup] = useState(false);
    const [icon, setIcon] = useState(false);

    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSingleImage(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });


    const onDropMultiple = (acceptedFiles) => {
        const newImages = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file) // إنشاء معاينة محلية للصور الجديدة
        }));
        setMultipleImages([...multipleImages, ...newImages]); // إضافة الصور الجديدة إلى القائمة
    };

    const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({onDrop: onDropMultiple});


    const onDropMultipleColor = (acceptedFiles) => {
        const newColorImages = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file) // إنشاء معاينة محلية للصور الجديدة
        }));
        setMultipleImagesColor([...multipleImagesColor, ...newColorImages]); // إضافة الصور الجديدة إلى القائمة
    };

    const { getRootProps: getRootPropsMultipleColor, getInputProps: getInputPropsMultipleColor } = useDropzone({
        onDrop: onDropMultipleColor
    });


    const resetInputs = () => {
        setTitleEn('');
        setTitleAr('');
        setDescriptionEn('');
        setDescriptionAr('');
        setPrice('');
        setQuantity('');
        // setSku('');
        setPriceInput('');
        setTimePriceInputs([{ hours: '', price: '' }]); // إرجاع حقل واحد فارغ للوقت والسعر
        setSingleImage([]); // إرجاع مصفوفة فارغة للصور الفردية
        setMultipleImages([]); // إرجاع مصفوفة فارغة للصور المتعددة
        setMultipleImagesColor([]); // إرجاع مصفوفة فارغة لصور الألوان
        setSizes([]); // إفراغ المقاسات المختارة
        setCustomSizes([{ size: '' }]); // إعادة تعيين المقاسات المخصصة
        setTypeCategory_id(''); // إفراغ الفئة
        setTypeSubCategory(''); // إفراغ الفئة الفرعية
        setMaterial(''); // إفراغ حقل المادة
        setMaterialComposition(''); // إفراغ حقل التركيب المادي
        setWarrantyCare(''); // إفراغ حقل الضمان والرعاية
        setGeneralSpecifications(''); // إفراغ حقل المواصفات العامة
        setNatureComfort(''); // إفراغ حقل الراحة الطبيعية
        setOccasion(''); // إفراغ حقل المناسبة
        setModel(''); // إفراغ حقل الموديل
      };
    
      function uploadTask(e) {
        e.preventDefault(); // منع إعادة تحميل الصفحة
        let isError = false;
    
        // تحقق من أن جميع الحقول ليست فارغة
        if (
            titleEn.trim() === '' ||
            titleAr.trim() === '' ||
            descriptionEn.trim() === '' ||
            descriptionAr.trim() === '' ||
            price === '' ||
            quantity === '' ||
            priceInput.trim() === '' ||
            timePriceInputs.some(input => input.hours === '' || input.price === '') || // تأكد أن كل حقل ساعات وسعر غير فارغ
            singleImage.length === 0 || // تأكد من وجود صورة واحدة على الأقل
            multipleImages.length === 0 || // تأكد من وجود صور متعددة
            sizes.length === 0 || // تأكد من اختيار المقاسات
            typeCategory_id === '' || // تأكد من اختيار الفئة
            typeSubCategory.trim() === '' || // تأكد من اختيار الفئة الفرعية
            material.trim() === '' ||
            materialComposition.trim() === '' ||
            warrantyCare.trim() === '' ||
            generalSpecifications.trim() === '' ||
            natureComfort.trim() === '' ||
            occasion.trim() === '' ||
            model.trim() === ''
        ) {
            // إذا كان هناك خطأ أو حقل غير ممتلئ، يتم تنفيذ هذه الإجراءات
            isError = true;
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
        }
    
        if (!isError) {
            const productData = {
                name_en: titleEn,
                name_ar: titleAr,
                description_ar: descriptionAr,
                description_en: descriptionEn,
                category_id: typeCategory_id,
                ad_id: null,
                supplier_id: null,
                status: typeSubCategory, 
                brand_id: null,
    
                main_img: singleImage,
                
                TimePrice: timePriceInputs,
                Delivery_price: priceInput,
    
                images: multipleImages,
                colors: multipleImagesColor,
                sizes: sizes, 
    
                variants: [
                    {
                        size: sizes[0], 
                        quantity: parseInt(quantity),
                        price: parseFloat(price),
                        material: material,
                        material_composition: materialComposition,
                        care_instructions: warrantyCare,
                        nature_comfort: natureComfort,
                        occasion: occasion,
                        model: model,
                        display_resolution: null,
                        battery_capacity: null,
                        storage_type: null,
                        ram: null,
                        camera_features: null
                    }
                ]
            };
            
            // إرسال البيانات إلى الـ backend
            dispatch(post_product(productData)).then((action) => {
                if (action.payload && action.payload.success) {
                    console.log('Product added successfully!');
                    resetInputs(); // استدعاء resetInputs لمسح الحقول
                }
            });
            setShowPopup(false);
        }
    }
    
    
    function editProduct(e) {
        e.preventDefault(); // منع إعادة تحميل الصفحة
        let isError = false;
    
        // تحقق من أن جميع الحقول ليست فارغة
        if (
            titleEn.trim() === '' ||
            titleAr.trim() === '' ||
            descriptionEn.trim() === '' ||
            descriptionAr.trim() === '' ||
            price === '' ||
            quantity === '' ||
            priceInput.trim() === '' ||
            timePriceInputs.some(input => input.hours === '' || input.price === '') || // تأكد أن كل حقل ساعات وسعر غير فارغ
            singleImage.length === 0 || // تأكد من وجود صورة واحدة على الأقل
            multipleImages.length === 0 || // تأكد من وجود صور متعددة
            sizes.length === 0 || // تأكد من اختيار المقاسات
            typeCategory_id === '' || // تأكد من اختيار الفئة
            typeSubCategory.trim() === '' || // تأكد من اختيار الفئة الفرعية
            material.trim() === '' ||
            materialComposition.trim() === '' ||
            warrantyCare.trim() === '' ||
            generalSpecifications.trim() === '' ||
            natureComfort.trim() === '' ||
            occasion.trim() === '' ||
            model.trim() === ''
        ) {
            // إذا كان هناك خطأ أو حقل غير ممتلئ، يتم تنفيذ هذه الإجراءات
            isError = true;
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
        }
    
        // إذا لم يكن هناك خطأ (جميع الحقول ممتلئة)
        if (!isError) {
            // جمع البيانات في كائن
            const productData = {
                name_en: titleEn,
                name_ar: titleAr,
                description_ar: descriptionAr,
                description_en: descriptionEn,
                category_id: typeCategory_id,
                ad_id: null,
                supplier_id: null,
                status: typeSubCategory, 
                brand_id: null,
    
                main_img: singleImage,
                
                TimePrice: timePriceInputs,
                Delivery_price: priceInput,
    
                images: multipleImages,
                colors: multipleImagesColor,
                sizes: sizes, 
    
                variants: [
                    {
                        size: [0], 
                        quantity: parseInt(quantity),
                        price: parseFloat(price),
                        material: material,
                        material_composition: materialComposition,
                        care_instructions: warrantyCare,
                        nature_comfort: natureComfort,
                        occasion: occasion,
                        model: model,
                        display_resolution: null,
                        battery_capacity: null,
                        storage_type: null,
                        ram: null,
                        camera_features: null
                    }
                ]
            };
    
            // إرسال البيانات للتعديل
            dispatch(edit_product({ productData, id: productD.id })).then((action) => {
                if (action.payload && action.payload.success) {
                    console.log('Product updated successfully!');
                    resetInputs(); // استدعاء resetInputs لمسح الحقول
                }
            });
            setShowPopup(false);
        }
    }
    const allSizes = ["S", "M", "L", "XL", "XXL", "40", "41", "42", "43", "44"];

    // التعامل مع اختيار المقاسات الجاهزة
    const handleSizeChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSizes((prev) => [...prev, value]);
        } else {
            setSizes((prev) => prev.filter((size) => size !== value));
        }
        setShowSize(false);
        // setNl(false)

    };

    // إضافة حقل جديد للمقاسات المخصصة
    const addSizeField = () => {
        setCustomSizes([...customSizes, { size: "" }]);
    };

    // تحديث المقاس المكتوب في الحقول المخصصة
    const handleCustomSizeChange = (index, value) => {
        const updatedSizes = [...customSizes];
        updatedSizes[index].size = value;
        setCustomSizes(updatedSizes);
    };

    // إضافة المقاس المخصص إلى الـ sizes
    const addCustomSizeToList = (size) => {
        if (size && !sizes.includes(size)) {
            setSizes((prev) => [...prev, size]);
        }
    };

    const handlePriceChange = (event) => {
        setPriceInput(event.target.value);
    };

    // وظيفة لإضافة حقل جديد
    const addTimePriceField = () => {
        setTimePriceInputs([...timePriceInputs, { hours: "", price: "" }]);
    };

    // وظيفة لتحديث المدخلات
    const handleTimePriceChange = (index, field, value) => {
        const updatedInputs = [...timePriceInputs];
        updatedInputs[index][field] = value;
        setTimePriceInputs(updatedInputs);
    };

    const saveDeliveryDetails = () => {
        console.log(timePriceInputs); // هنا ممكن تبعتهم للسيرفر أو تخزنهم في لستة
    };


    const[icon5, setIcon5] = useState(false)
    function handelClick5() {
      setIcon5(!icon5)
    }

    const[icon6, setIcon6] = useState(false)
    function handelClick6() {
      setIcon6(!icon6)
    }
  


    return (
        <div className="AddProduct">
            <Header />
            
           <div className="container">
            {loadingPost_product && <Loader />}
            {loadingEdit_product && <Loader />}
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i className="font-size3"><FontAwesomeIcon icon={faPlusCircle} /></i>
                        <p>{t('addProduct.title')}</p>
                    </div>
                </div>
                <div className="container">
                    <form className="row justify-content-between mt-3">
                        <div className="col-7">
                            <div className="div1">

                                <label htmlFor='title'>Name Product (En)</label>
                                <input type="text" id="title" onChange={(e) => setTitleEn(e.target.value)} value={titleEn} />
                            </div>
                            <div className="div1">
                                <label htmlFor='title'>Name Product (Ar)</label>
                                <input type="text" id="title" onChange={(e) => setTitleAr(e.target.value)} value={titleAr} />
                            </div>

                            <div className="div2">
                                <label htmlFor='description'>{t('addProduct.productDescription')}(En)</label>
                                <input type="text" id="description" onChange={(e) => setDescriptionEn(e.target.value)} value={descriptionEn} />
                            </div>
                            <div className="div2">
                                <label htmlFor='description'>{t('addProduct.productDescription')}(Ar)</label>
                                <input type="text" id="description" onChange={(e) => setDescriptionAr(e.target.value)} value={descriptionAr} />
                            </div>

                            <div className="Add_img">
                                <p>{t('addCategory.singlePhoto')}</p>
                                <div className="add" {...getRootPropsSingle()}>
                                    <input {...getInputPropsSingle()} />
                                    {!singleImage && (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addCategory.dragAndDropSingle')}</p>
                                        </div>
                                    )}
                                    {singleImage && (
                                        <img src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} alt="Preview" className="img" />
                                    )}
                                </div>
                            </div>

                            <div className="Add_img">
                                <p>{t('addProduct.media')}</p>
                                <div className="add" {...getRootPropsMultiple()}>
                                    <input {...getInputPropsMultiple()} />
                                    {!multipleImages?.length && (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addProduct.dragAndDropMultiple')}</p>
                                        </div>
                                    )}
                                    <div className={`image-preview ${multipleImages?.length === 1 ? 'single' : 'multiple'} row`}>
                                        {multipleImages?.map((file, index) => (
                                            <img 
                                                key={index} 
                                                src={file instanceof File ? file.preview : file?.image} // عرض الصورة من الـ backend أو المعاينة المحلية
                                                alt={`معاينة التحميل ${index}`} 
                                                className="imgs col-4 mb-3" 
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-between mt-4">

                                <div className="div2 col-5">
                                    <label htmlFor='price'>{t('addProduct.price')}</label>
                                    <input type="number" id="price" onChange={(e) => setPrice(e.target.value)} value={price} />
                                </div>

                                <div className="div2 col-5">
                                    <label htmlFor='quantity'>{t('addProduct.quantity')}</label>
                                    <input type="number" id="quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
                                </div>

                                <div className="div2 mb-4 col-12">
                                        <label className="m-0">أضفة تكلفة التوصيل</label>
                                        <input
                                            type="number"
                                            placeholder="أدخل تكلفة التوصيل"
                                            value={priceInput}
                                            onChange={handlePriceChange}
                                        />
                                    </div>

                                <div className="delivery-price-selector">

                                    <div className="delivery-time-price-fields">
                                        <p >أضف عدد الساعات وسعر التوصيل</p>

                                        {/* هنا الحقول اللي بتتكرر مع كل ضغطة على زر + */}
                                        {timePriceInputs.map((input, index) => (
                                            <div key={index} className="input-group mb-4">
                                                {/* إدخال عدد الساعات */}
                                                <input
                                                    type="text"
                                                    placeholder="أدخل عدد الساعات"
                                                    value={input.hours}
                                                    onChange={(e) => handleTimePriceChange(index, "hours", e.target.value)}
                                                />
                                                {/* إدخال سعر التوصيل */}
                                                <input
                                                    type="text"
                                                    placeholder="أدخل سعر التوصيل"
                                                    value={input.price}
                                                    onChange={(e) => handleTimePriceChange(index, "price", e.target.value)}
                                                />
                                            </div>
                                        ))}

                                        <div className="addTimePriceField">
                                            {/* زرار لإضافة حقلين جديدين */}
                                            <p className="addTime" onClick={addTimePriceField}>+</p>

                                        {/* زرار لحفظ المدخلات */}
                                        <p className="addTime" onClick={saveDeliveryDetails}>إضافة</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="project_data col-5">
                        <div className="div">
                        <p className="m-0">{t('addProduct.category')}</p>

                            <div className="box_AddCompany div4 w-100" onClick={handelClick5}>
                                <div className="heade">
                                    <span>{typeCategory}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight}/></i>
                                </div>
                                {icon5 &&
                                    <div className="cat">
                                        {categorys?.data?.data.map((e, i) => 
                                            <p onClick={() => {
                                                setTypeCategory(e.name);
                                                setTypeCategory_id(e.id);
                                                setIcon5(!icon5);
                                            }}>
                                            {e.name}
                                            </p>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
 
                        <div className="div mt-4">
                            <p className="m-0">Product Status</p>

                            <div className="box_AddCompany div4 w-100" onClick={handelClick6}>
                                <div className="heade">
                                    <span>{typeSubCategory}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight}/></i>
                                </div>
                                {icon6 &&
                                    <div className="cat">
                                        {product_statu?.data?.data?.product_status_data.map((e, i) => 
                                            <p onClick={() => (setTypeSubCategory(e), setIcon6(!icon6))}>{e}</p>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                            
                        <div className="Add_img mt-4">
                            <p className="mb-0">Color images</p>
                            {/* {errorImageUpload && <p className="error">{errorImageUpload}</p>} */}

                            <div className="add" {...getRootPropsMultipleColor()}>
                                <input {...getInputPropsMultipleColor()} />
                                {!multipleImagesColor.length && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addProduct.dragAndDropMultiple')}</p>
                                    </div>
                                )}
                                <div className={`image-preview ${multipleImagesColor.length === 1 ? 'single' : 'multiple'} row`}>
                                    {multipleImagesColor.map((file, index) => (
                                        <img 
                                            key={index} 
                                            src={file instanceof File ? file.preview : file.image} // عرض الصورة من الـ backend أو المعاينة المحلية
                                            alt={index} 
                                            className="imgs col-4 mb-3" 
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                            <div className="box mt-4" onClick={() => setIcon(!icon)}>
                <label htmlFor='productType'>مقاسات المنتج</label>
                <div className="heade">
                    <span>اختر المقاسات</span>
                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                </div>
            </div>

            {icon && (
                <div className="size-selection">
                    {/* المقاسات الجاهزة */}
                    {allSizes.map((size, index) => (
                        <div key={index} className="selection">
                            <input 
                                type="checkbox" 
                                id={`size-${size}`} 
                                value={size} 
                                onChange={handleSizeChange}
                            />
                            <label htmlFor={`size-${size}`}>{size}</label>
                        </div>
                    ))}

                    {/* المقاسات المخصصة */}
                    {customSizes.map((input, index) => (
                        <div key={index} className="input-group">
                            <input
                                type="text"
                                placeholder="أدخل مقاس مخصص"
                                value={input.size}
                                onChange={(e) => handleCustomSizeChange(index, e.target.value)}
                            />
                            <p 
                                className="addSize" 
                                onClick={() => addCustomSizeToList(input.size)}
                            >
                                إضافة
                            </p>
                        </div>
                    ))}

                    {/* زرار لإضافة حقل جديد */}
                    <p className="addSizeField" onClick={addSizeField}>+</p>
                </div>
            )}


            <div className="size-selection-text">
                {/* {productD ? (
                    <>
                        {showSize === true &&      
                            <p>
                                المقاسات المختارة: 
                                {sizes.map((item, index) => (
                                    <span key={index}>{item.size}, </span>
                                ))}
                            </p>
                        }
                        {showSize === false && <p>المقاسات المختارة: {sizes.join(', ')}</p>}
                    </>
                ) : (
                    <p>المقاسات المختارة: {sizes.join(', ')}</p>
                )} */}
                                            <p>
                                المقاسات المختارة: 
                                {sizes.map((item, index) => (
                                    <span key={index}>{item}, </span>
                                ))}
                            </p>
            </div>



            
                <div className="div2 col-3">
              <label htmlFor='material' className="mb-3">Material</label>
              <input
                type="text"
                id="material"
                onChange={(e) => setMaterial(e.target.value)}
                value={material}
                placeholder="Material"
              />
            </div>

            <div className="div2 col-3">
              <input
                type="text"
                id="materialComposition"
                onChange={(e) => setMaterialComposition(e.target.value)}
                value={materialComposition}
                placeholder="Material Composition"
              />
            </div>

            <div className="div2 col-3">
              <label htmlFor='warrantyCare' className="mb-3">Warranty & Care</label>
              <input
                type="text"
                id="warrantyCare"
                onChange={(e) => setWarrantyCare(e.target.value)}
                value={warrantyCare}
                placeholder="Care Instructions"
              />
            </div>

            <div className="div2 col-3">
              <label htmlFor='generalSpecifications' className="mb-3">General Specifications</label>
              <input
                type="text"
                id="generalSpecifications"
                onChange={(e) => setGeneralSpecifications(e.target.value)}
                value={generalSpecifications}
                placeholder="General Specifications"
              />
            </div>

            <div className="div2 col-3">
              <input
                type="text"
                id="natureComfort"
                onChange={(e) => setNatureComfort(e.target.value)}
                value={natureComfort}
                placeholder="Nature's Comfort"
              />
            </div>

            <div className="div2 col-3">
              <input
                type="text"
                id="occasion"
                onChange={(e) => setOccasion(e.target.value)}
                value={occasion}
                placeholder="Occasion"
              />
            </div>

            <div className="div2 col-3">
              <input
                type="text"
                id="model"
                onChange={(e) => setModel(e.target.value)}
                value={model}
                placeholder="Model"
              />
            </div>

                </div >
                    <button className='col-4 mt-5 mb-3' onClick={uploadTask} type="submit">{t('addProduct.create')}</button>
                    <button className='col-4 mt-5 mb-3' onClick={editProduct} type="submit">Edit</button>
                </form>
            </div>

            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addProduct.errorFields')}</div>
        </div>
    );
}

export default AddProduct;

// import React, { useState, useCallback } from "react";
// import './AddProduct.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCartPlus, faCheck, faChevronRight, faCloudUpload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// import { useDropzone } from 'react-dropzone';
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';
// import { category, product_status, post_product,  edit_product} from "./Redux/API";
// import { useEffect } from 'react';
// import Loader from './Loader';
// import { useTranslation } from "react-i18next";
// import Header from "./Header/Header";


// function AddProduct() {
//     const { t } = useTranslation();
//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const productD = useSelector(state => state?.products.productD);
//     console.log(productD)
//     useEffect(() => {
//         if (productD) {
//           setTitleEn(productD?.name_en || ''); 
//           setTitleAr(productD?.name_ar || ''); 
//           setDescriptionEn(productD?.description_en || ''); 
//           setDescriptionAr(productD?.description_ar || ''); 
//           setPrice(productD?.new_price || ''); 
//           setQuantity(productD?.remaining_quantity || ''); 
//           setPriceInput(productD?.delivery_price || ''); 
//         //   setSku(productD.sku || ''); // SKU إن وجد
//           setSingleImage(productD?.main_image || []); 
//         //   setMultipleImagesColor(productD?.colors || []);
//         //   setMultipleImages(productD?.images || []);
//         //   setMultipleImages();
//           setTypeCategory(productD?.category || ''); 
//           setTypeSubCategory(productD?.status || ''); 
//           setSizes(productD?.sizes?.map(t => t.size) || []); // الأحجام
//         //   setSizes(productD?.sizes || []); // الأحجام
//           setCustomSizes([{ size: '' }]); // الأحجام المخصصة (إذا لزم الأمر)
          
//           // الحقول الإضافية
//           setMaterial(productD?.product_varients[0].material || ''); // المادة
//           setMaterialComposition(productD?.product_varients[0].material_composition || ''); // المادة
//           setWarrantyCare(productD?.product_varients[0].care_instructions || ''); // المادة
//           setGeneralSpecifications(productD?.product_varients[0].material_composition || ''); // المادة
//           setNatureComfort(productD?.product_varients[0].nature_comfort || ''); // المادة
//           setOccasion(productD?.product_varients[0].occasion || ''); // المادة
//           setModel(productD.model || ''); // الموديل
//           // حقول الشحن
//           setTimePriceInputs(
//             productD.shippings?.map(shipping => ({ hours: shipping.key, price: shipping.value })) || 
//             [{ hours: '', price: '' }]
//           );
    
//           // إذا كان هناك بيانات إضافية مثل المواصفات العامة والراحة والطبيعة والموعد المناسب
//         }
//       }, [productD]);



//     //   const [multipleImages, setMultipleImages] = useState([]);
//       const [multipleImagesColor, setMultipleImagesColor] = useState([]);
      
//       const [multipleImages, setMultipleImages] = useState([]);

//       // الفنكشن اللي بتحوّل URL إلى File
//       const urlToFile = async (url, filename, mimeType) => {
//         try {
//           const response = await fetch(url);
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
//           const blob = await response.blob();
//           return new File([blob], filename, { type: mimeType });
//         } catch (error) {
//           console.error("Error fetching the file: ", error);
//           return null; // عشان تتجنب مشاكل لو فشل الـ fetch
//         }
//       };
    
//       // الفنكشن اللي بتحوّل Array من URLs إلى Array من Files
//       const convertUrlsToFiles = async (urlsArray) => {
//         const files = await Promise.all(
//           urlsArray.map((url, index) => {
//             const mimeType = url.endsWith('.png') ? 'image/png' : 'image/jpeg';
//             return urlToFile(url, `image_${index + 1}`, mimeType);
//           })
//         );
//         return files.filter(file => file !== null); // نتجنب null files
//       };
    
//       // لما الكومبوننت يتحمل، بنحمل الصور ونخزنهم في `multipleImages`
//       useEffect(() => {
//         if (productD?.images) {
//           const urlsArray = productD.images.map(image => image.image);
//           const loadFiles = async () => {
//             try {
//               const files = await convertUrlsToFiles(urlsArray);
//               setMultipleImages(files);
//             } catch (error) {
//               console.error("Error loading files: ", error);

//             }
//           };
    
//           loadFiles(); // تأكدنا من وضع `await` لتحميل الملفات
//         }
//       }, [productD]); // نضيف `productD` للـ dependency array
    



    
// console.log(multipleImages)
// console.log(productD?.images?.map(image => image.image))
//     // // دالة لتحويل الروابط الخاصة بالألوان إلى ملفات
//     // async function urlToFiles(url, filename, mimeType) {
//     //     try {
//     //         const response = await fetch(url);
//     //         if (!response.ok) {
//     //             throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
//     //         }
//     //         const blob = await response.blob();
//     //         return new File([blob], filename, { type: mimeType });
//     //     } catch (error) {
//     //         console.error(`Error fetching the file from ${url}:`, error);
//     //         throw error;
//     //     }
//     // }
    
//     // async function convertUrlsToFiless(urls) {
//     //     if (!urls || urls.length === 0) return []; // تحقق من وجود الروابط قبل التحويل
    
//     //     const files = await Promise.all(
//     //         urls.map(async (url, index) => {
//     //             let mimeType = 'image/png'; // الافتراضي هو PNG
//     //             if (url.endsWith('.jpg') || url.endsWith('.jpeg')) {
//     //                 mimeType = 'image/jpeg';
//     //             }
    
//     //             const filename = `image_${index + 1}.${mimeType.split('/')[1]}`; // توليد اسم ملف
//     //             return await urlToFiles(url, filename, mimeType);
//     //         })
//     //     );
//     //     return files;
//     // }
    
//     // // الروابط الخاصة بصور الألوان
//     // const colorsImageUrls = productD?.colors?.map(color => color.image) || [];
    
//     // // شرط لمنع التكرار: تحقق إذا كانت الصور قد تغيرت بالفعل
//     // if (colorsImageUrls.length > 0 && multipleImagesColor.length === 0) {
//     //     convertUrlsToFiless(colorsImageUrls).then(files => {
//     //         setMultipleImagesColor(files); // تعيين الملفات في setMultipleImagesColor
//     //     }).catch(error => {
//     //         console.error('Error converting color URLs to files:', error);
//     //     });
//     // }




//     const loadingCategory = useSelector(state => state.Data.loadingCategory);

//     const product_statu = useSelector(state => state.Data.product_status);
//     const loadingPoduct_status = useSelector(state => state.Data.loadingProduct);
//     const loadingEdit_product = useSelector(state => state.Data.loadingEdit_product);

//     const post_products = useSelector(state => state.Data.post_product);
//     const loadingPost_product = useSelector(state => state.Data.loadingPost_product);
//     console.log(product_statu)

//     useEffect(() => {
//         dispatch(product_status()); 
//     }, [dispatch]);

//     const categorys = useSelector(state => state.Data.category);
//     console.log(categorys?.data?.data)

//     useEffect(() => {
//       dispatch(category())
//     }, [dispatch])

//  const [titleEn, setTitleEn] = useState('');
//   const [titleAr, setTitleAr] = useState('');
//   const [descriptionEn, setDescriptionEn] = useState('');
//   const [descriptionAr, setDescriptionAr] = useState('');
//   const [price, setPrice] = useState('');
//   const [quantity, setQuantity] = useState('');
//   const [priceInput, setPriceInput] = useState('');
//   const [timePriceInputs, setTimePriceInputs] = useState([{ hours: '', price: '' }]);
//   const [singleImage, setSingleImage] = useState([]);
// //   const [multipleImages, setMultipleImages] = useState([]);
// //   const [multipleImagesColor, setMultipleImagesColor] = useState([]);
//   const [sizes, setSizes] = useState([]);
//   const [customSizes, setCustomSizes] = useState([{ size: '' }]);
//   const [typeCategory, setTypeCategory] = useState('');
//   const [typeCategory_id, setTypeCategory_id] = useState(null);
//   const [typeSubCategory, setTypeSubCategory] = useState('');

//   // إضافة الحقول الجديدة
//   const [material, setMaterial] = useState('');
//   const [materialComposition, setMaterialComposition] = useState('');
//   const [warrantyCare, setWarrantyCare] = useState('');
//   const [generalSpecifications, setGeneralSpecifications] = useState('');
//   const [natureComfort, setNatureComfort] = useState('');
//   const [occasion, setOccasion] = useState('');
//   const [model, setModel] = useState('');

//   const [showSize, setShowSize] = useState(true);



//     const [showPopup, setShowPopup] = useState(false);
//     const [icon, setIcon] = useState(false);

//     const onDropSingle = useCallback((acceptedFiles) => {
//         if (acceptedFiles.length > 0) {
//             setSingleImage(acceptedFiles[0]);
//         }
//     }, []);

//     const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });


//     const onDropMultiple = (acceptedFiles) => {
//         const newImages = acceptedFiles.map(file => Object.assign(file, {
//             preview: URL.createObjectURL(file) // إنشاء معاينة محلية للصور الجديدة
//         }));
//         setMultipleImages([...multipleImages, ...newImages]); // إضافة الصور الجديدة إلى القائمة
//     };

//     const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({onDrop: onDropMultiple});


//     const onDropMultipleColor = (acceptedFiles) => {
//         const newColorImages = acceptedFiles.map(file => Object.assign(file, {
//             preview: URL.createObjectURL(file) // إنشاء معاينة محلية للصور الجديدة
//         }));
//         setMultipleImagesColor([...multipleImagesColor, ...newColorImages]); // إضافة الصور الجديدة إلى القائمة
//     };

//     const { getRootProps: getRootPropsMultipleColor, getInputProps: getInputPropsMultipleColor } = useDropzone({
//         onDrop: onDropMultipleColor
//     });


//     const resetInputs = () => {
//         setTitleEn('');
//         setTitleAr('');
//         setDescriptionEn('');
//         setDescriptionAr('');
//         setPrice('');
//         setQuantity('');
//         // setSku('');
//         setPriceInput('');
//         setTimePriceInputs([{ hours: '', price: '' }]); // إرجاع حقل واحد فارغ للوقت والسعر
//         setSingleImage([]); // إرجاع مصفوفة فارغة للصور الفردية
//         setMultipleImages([]); // إرجاع مصفوفة فارغة للصور المتعددة
//         setMultipleImagesColor([]); // إرجاع مصفوفة فارغة لصور الألوان
//         setSizes([]); // إفراغ المقاسات المختارة
//         setCustomSizes([{ size: '' }]); // إعادة تعيين المقاسات المخصصة
//         setTypeCategory_id(''); // إفراغ الفئة
//         setTypeSubCategory(''); // إفراغ الفئة الفرعية
//         setMaterial(''); // إفراغ حقل المادة
//         setMaterialComposition(''); // إفراغ حقل التركيب المادي
//         setWarrantyCare(''); // إفراغ حقل الضمان والرعاية
//         setGeneralSpecifications(''); // إفراغ حقل المواصفات العامة
//         setNatureComfort(''); // إفراغ حقل الراحة الطبيعية
//         setOccasion(''); // إفراغ حقل المناسبة
//         setModel(''); // إفراغ حقل الموديل
//       };
    
//       function uploadTask(e) {
//         e.preventDefault(); // منع إعادة تحميل الصفحة
//         let isError = false;
    
//         // تحقق من أن جميع الحقول ليست فارغة
//         if (
//             titleEn.trim() === '' ||
//             titleAr.trim() === '' ||
//             descriptionEn.trim() === '' ||
//             descriptionAr.trim() === '' ||
//             price === '' ||
//             quantity === '' ||
//             priceInput.trim() === '' ||
//             timePriceInputs.some(input => input.hours === '' || input.price === '') || // تأكد أن كل حقل ساعات وسعر غير فارغ
//             singleImage.length === 0 || // تأكد من وجود صورة واحدة على الأقل
//             multipleImages.length === 0 || // تأكد من وجود صور متعددة
//             sizes.length === 0 || // تأكد من اختيار المقاسات
//             typeCategory_id === '' || // تأكد من اختيار الفئة
//             typeSubCategory.trim() === '' || // تأكد من اختيار الفئة الفرعية
//             material.trim() === '' ||
//             materialComposition.trim() === '' ||
//             warrantyCare.trim() === '' ||
//             generalSpecifications.trim() === '' ||
//             natureComfort.trim() === '' ||
//             occasion.trim() === '' ||
//             model.trim() === ''
//         ) {
//             // إذا كان هناك خطأ أو حقل غير ممتلئ، يتم تنفيذ هذه الإجراءات
//             isError = true;
//             setShowPopup(true);
//             setTimeout(() => setShowPopup(false), 3000);
//         }
    
//         if (!isError) {
//             const productData = {
//                 name_en: titleEn,
//                 name_ar: titleAr,
//                 description_ar: descriptionAr,
//                 description_en: descriptionEn,
//                 category_id: typeCategory_id,
//                 ad_id: null,
//                 supplier_id: null,
//                 status: typeSubCategory, 
//                 brand_id: null,
    
//                 main_img: singleImage,
                
//                 TimePrice: timePriceInputs,
//                 Delivery_price: priceInput,
    
//                 images: multipleImages,
//                 colors: multipleImagesColor,
//                 sizes: sizes, 
    
//                 variants: [
//                     {
//                         size: sizes[0], 
//                         quantity: parseInt(quantity),
//                         price: parseFloat(price),
//                         material: material,
//                         material_composition: materialComposition,
//                         care_instructions: warrantyCare,
//                         nature_comfort: natureComfort,
//                         occasion: occasion,
//                         model: model,
//                         display_resolution: null,
//                         battery_capacity: null,
//                         storage_type: null,
//                         ram: null,
//                         camera_features: null
//                     }
//                 ]
//             };
            
//             // إرسال البيانات إلى الـ backend
//             dispatch(post_product(productData)).then((action) => {
//                 if (action.payload && action.payload.success) {
//                     console.log('Product added successfully!');
//                     resetInputs(); // استدعاء resetInputs لمسح الحقول
//                 }
//             });
//             setShowPopup(false);
//         }
//     }
    
    
//     function editProduct(e) {
//         e.preventDefault(); // منع إعادة تحميل الصفحة
//         let isError = false;
    
//         // تحقق من أن جميع الحقول ليست فارغة
//         if (
//             titleEn.trim() === '' ||
//             titleAr.trim() === '' ||
//             descriptionEn.trim() === '' ||
//             descriptionAr.trim() === '' ||
//             price === '' ||
//             quantity === '' ||
//             priceInput.trim() === '' ||
//             timePriceInputs.some(input => input.hours === '' || input.price === '') || // تأكد أن كل حقل ساعات وسعر غير فارغ
//             singleImage.length === 0 || // تأكد من وجود صورة واحدة على الأقل
//             multipleImages.length === 0 || // تأكد من وجود صور متعددة
//             sizes.length === 0 || // تأكد من اختيار المقاسات
//             typeCategory_id === '' || // تأكد من اختيار الفئة
//             typeSubCategory.trim() === '' || // تأكد من اختيار الفئة الفرعية
//             material.trim() === '' ||
//             materialComposition.trim() === '' ||
//             warrantyCare.trim() === '' ||
//             generalSpecifications.trim() === '' ||
//             natureComfort.trim() === '' ||
//             occasion.trim() === '' ||
//             model.trim() === ''
//         ) {
//             // إذا كان هناك خطأ أو حقل غير ممتلئ، يتم تنفيذ هذه الإجراءات
//             isError = true;
//             setShowPopup(true);
//             setTimeout(() => setShowPopup(false), 3000);
//         }
    
//         // إذا لم يكن هناك خطأ (جميع الحقول ممتلئة)
//         if (!isError) {
//             // جمع البيانات في كائن
//             const productData = {
//                 name_en: titleEn,
//                 name_ar: titleAr,
//                 description_ar: descriptionAr,
//                 description_en: descriptionEn,
//                 category_id: typeCategory_id,
//                 ad_id: null,
//                 supplier_id: null,
//                 status: typeSubCategory, 
//                 brand_id: null,
    
//                 main_img: singleImage,
                
//                 TimePrice: timePriceInputs,
//                 Delivery_price: priceInput,
    
//                 images: multipleImages,
//                 colors: multipleImagesColor,
//                 sizes: sizes, 
    
//                 variants: [
//                     {
//                         size: [0], 
//                         quantity: parseInt(quantity),
//                         price: parseFloat(price),
//                         material: material,
//                         material_composition: materialComposition,
//                         care_instructions: warrantyCare,
//                         nature_comfort: natureComfort,
//                         occasion: occasion,
//                         model: model,
//                         display_resolution: null,
//                         battery_capacity: null,
//                         storage_type: null,
//                         ram: null,
//                         camera_features: null
//                     }
//                 ]
//             };
    
//             // إرسال البيانات للتعديل
//             dispatch(edit_product({ productData, id: productD.id })).then((action) => {
//                 if (action.payload && action.payload.success) {
//                     console.log('Product updated successfully!');
//                     resetInputs(); // استدعاء resetInputs لمسح الحقول
//                 }
//             });
//             setShowPopup(false);
//         }
//     }
//     const allSizes = ["S", "M", "L", "XL", "XXL", "40", "41", "42", "43", "44"];

//     // التعامل مع اختيار المقاسات الجاهزة
//     const handleSizeChange = (e) => {
//         const value = e.target.value;
//         if (e.target.checked) {
//             setSizes((prev) => [...prev, value]);
//         } else {
//             setSizes((prev) => prev.filter((size) => size !== value));
//         }
//         setShowSize(false);
//         // setNl(false)

//     };

//     // إضافة حقل جديد للمقاسات المخصصة
//     const addSizeField = () => {
//         setCustomSizes([...customSizes, { size: "" }]);
//     };

//     // تحديث المقاس المكتوب في الحقول المخصصة
//     const handleCustomSizeChange = (index, value) => {
//         const updatedSizes = [...customSizes];
//         updatedSizes[index].size = value;
//         setCustomSizes(updatedSizes);
//     };

//     // إضافة المقاس المخصص إلى الـ sizes
//     const addCustomSizeToList = (size) => {
//         if (size && !sizes.includes(size)) {
//             setSizes((prev) => [...prev, size]);
//         }
//     };

//     const handlePriceChange = (event) => {
//         setPriceInput(event.target.value);
//     };

//     // وظيفة لإضافة حقل جديد
//     const addTimePriceField = () => {
//         setTimePriceInputs([...timePriceInputs, { hours: "", price: "" }]);
//     };

//     // وظيفة لتحديث المدخلات
//     const handleTimePriceChange = (index, field, value) => {
//         const updatedInputs = [...timePriceInputs];
//         updatedInputs[index][field] = value;
//         setTimePriceInputs(updatedInputs);
//     };

//     const saveDeliveryDetails = () => {
//         console.log(timePriceInputs); // هنا ممكن تبعتهم للسيرفر أو تخزنهم في لستة
//     };


//     const[icon5, setIcon5] = useState(false)
//     function handelClick5() {
//       setIcon5(!icon5)
//     }

//     const[icon6, setIcon6] = useState(false)
//     function handelClick6() {
//       setIcon6(!icon6)
//     }
  


//     return (
//         <div className="AddProduct">
//             <Header />
            
//            <div className="container">
//             {loadingPost_product && <Loader />}
//             {loadingEdit_product && <Loader />}
//                 <div className='row align-items-center'>
//                     <div className='name_header d-flex'>
//                         <i className="font-size3"><FontAwesomeIcon icon={faPlusCircle} /></i>
//                         <p>{t('addProduct.title')}</p>
//                     </div>
//                 </div>
//                 <div className="container">
//                     <form className="row justify-content-between mt-3">
//                         <div className="col-7">
//                         <div className="div1">
//                             <label htmlFor='title'>{t('addProduct.productNameEn')}</label>
//                             <input type="text" id="title" onChange={(e) => setTitleEn(e.target.value)} value={titleEn} />
//                         </div>
//                         <div className="div1">
//                             <label htmlFor='title'>{t('addProduct.productNameAr')}</label>
//                             <input type="text" id="title" onChange={(e) => setTitleAr(e.target.value)} value={titleAr} />
//                         </div>

//                             <div className="div2">
//                                 <label htmlFor='description'>{t('addProduct.productDescription')}(En)</label>
//                                 <input type="text" id="description" onChange={(e) => setDescriptionEn(e.target.value)} value={descriptionEn} />
//                             </div>
//                             <div className="div2">
//                                 <label htmlFor='description'>{t('addProduct.productDescription')}(Ar)</label>
//                                 <input type="text" id="description" onChange={(e) => setDescriptionAr(e.target.value)} value={descriptionAr} />
//                             </div>

//                             <div className="Add_img">
//                                 <p>{t('addCategory.singlePhoto')}</p>
//                                 <div className="add" {...getRootPropsSingle()}>
//                                     <input {...getInputPropsSingle()} />
//                                     {!singleImage && (
//                                         <div className="upload-placeholder">
//                                             <i><FontAwesomeIcon icon={faCloudUpload} /></i>
//                                             <p>{t('addCategory.dragAndDropSingle')}</p>
//                                         </div>
//                                     )}
//                                     {singleImage && (
//                                         <img src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} alt="Preview" className="img" />
//                                     )}
//                                 </div>
//                             </div>

//                             <div className="Add_img">
//                                 <p>{t('addProduct.media')}</p>
//                                 <div className="add" {...getRootPropsMultiple()}>
//                                     <input {...getInputPropsMultiple()} />
//                                     {!multipleImages?.length && (
//                                         <div className="upload-placeholder">
//                                             <i><FontAwesomeIcon icon={faCloudUpload} /></i>
//                                             <p>{t('addProduct.dragAndDropMultiple')}</p>
//                                         </div>
//                                     )}
//                                     <div className={`image-preview ${multipleImages?.length === 1 ? 'single' : 'multiple'} row`}>
//                                         {multipleImages?.map((file, index) => (
//                                             <img 
//                                                 key={index} 
//                                                 src={file instanceof File ? file.preview : file?.image} // عرض الصورة من الـ backend أو المعاينة المحلية
//                                                 alt={`معاينة التحميل ${index}`} 
//                                                 className="imgs col-4 mb-3" 
//                                             />
//                                         ))}
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row justify-content-between mt-4">

//                                 <div className="div2 col-5">
//                                     <label htmlFor='price'>{t('addProduct.price')}</label>
//                                     <input type="number" id="price" onChange={(e) => setPrice(e.target.value)} value={price} />
//                                 </div>

//                                 <div className="div2 col-5">
//                                     <label htmlFor='quantity'>{t('addProduct.quantity')}</label>
//                                     <input type="number" id="quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
//                                 </div>

//                                 <div className="div2 mb-4 col-12">
//                                     <label className="m-0">{t('addProduct.deliveryCost')}</label>
//                                     <input type="number" placeholder={t('addProduct.deliveryCost')} value={priceInput} onChange={handlePriceChange} />

//                                     </div>

//                                 <div className="delivery-price-selector">

//                                     <div className="delivery-time-price-fields">
//                                         <p>{t('addProduct.deliveryHoursPrice')}</p>
//                                         {/* هنا الحقول اللي بتتكرر مع كل ضغطة على زر + */}
//                                         {timePriceInputs.map((input, index) => (
//                                             <div key={index} className="input-group mb-4">
//                                                 <input type="text" placeholder={t('addProduct.deliveryHours')} value={input.hours} onChange={(e) => handleTimePriceChange(index, "hours", e.target.value)} />
//                                                 <input type="text" placeholder={t('addProduct.deliveryPrice')} value={input.price} onChange={(e) => handleTimePriceChange(index, "price", e.target.value)} />
//                                             </div>
//                                         ))}

//                                         <div className="addTimePriceField">
//                                             <p className="addTime" onClick={addTimePriceField}>+</p>
//                                             <p className="addTime" onClick={saveDeliveryDetails}>{t('addProduct.add')}</p>
//                                         </div>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className="project_data col-5">
//                         <div className="div">
//                         <p className="m-0">{t('addProduct.category')}</p>

//                             <div className="box_AddCompany div4 w-100" onClick={handelClick5}>
//                                 <div className="heade">
//                                     <span>{typeCategory}</span>
//                                     <i><FontAwesomeIcon icon={faChevronRight}/></i>
//                                 </div>
//                                 {icon5 &&
//                                     <div className="cat">
//                                         {categorys?.data?.data.map((e, i) => 
//                                             <p onClick={() => {
//                                                 setTypeCategory(e.name);
//                                                 setTypeCategory_id(e.id);
//                                                 setIcon5(!icon5);
//                                             }}>
//                                             {e.name}
//                                             </p>
//                                         )}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
 
//                         <div className="div mt-4">
//                         <p className="m-0">{t('addProduct.status')}</p>

//                             <div className="box_AddCompany div4 w-100" onClick={handelClick6}>
//                                 <div className="heade">
//                                     <span>{typeSubCategory}</span>
//                                     <i><FontAwesomeIcon icon={faChevronRight}/></i>
//                                 </div>
//                                 {icon6 &&
//                                     <div className="cat">
//                                         {product_statu?.data?.data?.product_status_data.map((e, i) => 
//                                             <p onClick={() => (setTypeSubCategory(e), setIcon6(!icon6))}>{e}</p>
//                                         )}
//                                     </div>
//                                 }
//                             </div>
//                         </div>
                            
//                         <div className="Add_img mt-4">
//                             <p className="mb-0">{t('addProduct.colorImages')}</p>
//                             <div className="add" {...getRootPropsMultipleColor()}>
//                                 <input {...getInputPropsMultipleColor()} />
//                                 {!multipleImagesColor.length && (
//                                     <div className="upload-placeholder">
//                                         <i><FontAwesomeIcon icon={faCloudUpload} /></i>
//                                         <p>{t('addProduct.dragAndDropMultiple')}</p>
//                                     </div>
//                                 )}
//                                 <div className={`image-preview ${multipleImagesColor.length === 1 ? 'single' : 'multiple'} row`}>
//                                     {multipleImagesColor.map((file, index) => (
//                                         <img 
//                                             key={index} 
//                                             src={file instanceof File ? file.preview : file.image} // عرض الصورة من الـ backend أو المعاينة المحلية
//                                             alt={index} 
//                                             className="imgs col-4 mb-3" 
//                                         />
//                                     ))}
//                                 </div>
//                             </div>
//                         </div>

//                             <div className="box mt-4" onClick={() => setIcon(!icon)}>
//                 <label htmlFor='productType'>{t('addProduct.sizes')}</label>
//                 <div className="heade">
//                     <span>{t('addProduct.selectedSizes')}</span>
//                     <i><FontAwesomeIcon icon={faChevronRight} /></i>
//                 </div>
//             </div>

//             {icon && (
//                 <div className="size-selection">
//                     {/* المقاسات الجاهزة */}
//                     {allSizes.map((size, index) => (
//                         <div key={index} className="selection">
//                             <input 
//                                 type="checkbox" 
//                                 id={`size-${size}`} 
//                                 value={size} 
//                                 onChange={handleSizeChange}
//                             />
//                             <label htmlFor={`size-${size}`}>{size}</label>
//                         </div>
//                     ))}

//                     {/* المقاسات المخصصة */}
//                     {customSizes.map((input, index) => (
//                         <div key={index} className="input-group">
//                             <input
//                                 type="text"
//                                 placeholder="أدخل مقاس مخصص"
//                                 value={input.size}
//                                 onChange={(e) => handleCustomSizeChange(index, e.target.value)}
//                             />
//                             <p 
//                                 className="addSize" 
//                                 onClick={() => addCustomSizeToList(input.size)}
//                             >
//                                 إضافة
//                             </p>
//                         </div>
//                     ))}

//                     {/* زرار لإضافة حقل جديد */}
//                     <p className="addSizeField" onClick={addSizeField}>+</p>
//                 </div>
//             )}


//             <div className="size-selection-text">
//                 <p>
//                 {t('addProduct.selectedSizes')}: 
//                     {sizes.map((item, index) => (
//                         <span key={index}>{item}, </span>
//                     ))}
//                 </p>
//             </div>



            
//             <div className="div2 col-3">
//                 <label htmlFor='material' className="mb-3">{t('addProduct.material')}</label>
//                 <input type="text" id="material" onChange={(e) => setMaterial(e.target.value)} value={material} />
//               </div>
//               <div className="div2 col-3">
//                 <input type="text" id="materialComposition" onChange={(e) => setMaterialComposition(e.target.value)} value={materialComposition} placeholder={t('addProduct.materialComposition')} />
//               </div>
//               <div className="div2 col-3">
//                 <label htmlFor='warrantyCare' className="mb-3">{t('addProduct.warrantyCare')}</label>
//                 <input type="text" id="warrantyCare" onChange={(e) => setWarrantyCare(e.target.value)} value={warrantyCare} placeholder={t('addProduct.warrantyCare')} />
//               </div>
//               <div className="div2 col-3">
//                 <label htmlFor='generalSpecifications' className="mb-3">{t('addProduct.generalSpecifications')}</label>
//                 <input type="text" id="generalSpecifications" onChange={(e) => setGeneralSpecifications(e.target.value)} value={generalSpecifications} placeholder={t('addProduct.generalSpecifications')} />
//               </div>
//               <div className="div2 col-3">
//                 <input type="text" id="natureComfort" onChange={(e) => setNatureComfort(e.target.value)} value={natureComfort} placeholder={t('addProduct.natureComfort')} />
//               </div>
//               <div className="div2 col-3">
//                 <input type="text" id="occasion" onChange={(e) => setOccasion(e.target.value)} value={occasion} placeholder={t('addProduct.occasion')} />
//               </div>
//               <div className="div2 col-3">
//                 <input type="text" id="model" onChange={(e) => setModel(e.target.value)} value={model} placeholder={t('addProduct.model')} />
//               </div>

//                 </div >
//                     <button className='col-4 mt-5 mb-3' onClick={uploadTask} type="submit">{t('addProduct.create')}</button>
//                     <button className='col-4 mt-5 mb-3' onClick={editProduct} type="submit">{t('addProduct.edit')}</button>
//                 </form>
//             </div>

//             </div>
//             <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addProduct.errorFields')}</div>
//         </div>
//     );
// }

// export default AddProduct;























