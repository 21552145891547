import './Register.css';
import group from "../images/Group.png";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, login, verification, loginFirebase, register_vendor } from '../Redux/API';
import { sendReset, resetPassword, verifyReset } from '../Redux/API';
import { faCartPlus, faCheck, faChevronRight, faCloudUpload, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import image2 from '../images/4957136.jpg'
import Loader from '../Loader';


function Register() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const errorRegister = useSelector(state => state.Data.errorAddUser);
    const messageOTP = useSelector(state => state.Data.errorVerification);
    const errorLogin =useSelector(state => state.Data.errorLogin);


    const userData = useSelector(state => state.Data.userData);
    const logins = useSelector(state => state.Data.login);

    const loadingAddUser = useSelector(state => state.Data.loadingAddUser);
    const loadingVerification = useSelector(state => state.Data.loadingVerification);
    const loadingLogin = useSelector(state => state.Data.loadingLogin);

    // register_vendor
    const register_vendors = useSelector(state => state.Data.register_vendor);
    const loadingRegister_vendor = useSelector(state => state.Data.loadingRegister_vendor);

    console.log(register_vendors)


    // هنجيب البيانات المحفوظة من الـ localStorage
    const [getLocalStorage, setGetLocalStorage] = useState(() => {
        const savedData = window.localStorage.getItem('UserInfo');
        return savedData ? JSON.parse(savedData) : null;
    });

          // 1. إعداد states لكل حقل
  const [signupEmail, setSignupEmail] = useState(null); // البريد الإلكتروني
  const [signupPassword, setSignupPassword] = useState(''); // كلمة المرور
  const [companyName, setCompanyName] = useState(''); // اسم الشركة
  const [number, setNumber] = useState(''); // رقم الهاتف
  const [businessType, setBusinessType] = useState(''); // نوع النشاط
  const [productsNumber, setProductsNumber] = useState(null); // عدد المنتجات
  const [address, setAddress] = useState(''); // العنوان
  const [typeStore, setTypeStore] = useState(''); // نوع المتجر
  const [singleImage, setSingleImage] = useState([]); // حفظ الصورة المحملة

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    console.log(`Email validation for "${email}":`, isValid);
    return isValid;
};
  

    // لو فيه بيانات في localStorage هنعمل استعراض للإيميل والتوكن
    const [email, setEmail] = useState( getLocalStorage && getLocalStorage?.data && getLocalStorage?.data?.data?.user ? getLocalStorage?.data?.data?.user?.email : signupEmail);

    useEffect(() => {
        if (register_vendors) {
            setShowToken(true);
        }
    }, [register_vendors]);


    const [activeButton, setActiveButton] = useState('login');

  const [errorSingleImage, setErrorSingleImage] = useState(''); // الخطأ إذا حدث مشكلة في الصورة

    const [signupError, setSignupError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('customer');

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [showToken, setShowToken] = useState(false);

    const [showOTP, setShowOTP] = useState(false);
    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    
        // إعادة تعيين الحقول غير النشطة عند التبديل بين الزرين
        if (buttonName === 'signup') {
            setLoginEmail('');
            setLoginPassword('');
            setLoginError('');
        } else if (buttonName === 'login') {
            setSignupEmail('');
            setSignupPassword('');
            setSignupError('');
        }
    };

    useEffect(() => {
        if (activeButton === 'signup') {
            const isValid = signupEmail && signupPassword && companyName && number &&  businessType && productsNumber && address && typeStore && singleImage && isValidEmail(signupEmail) && isValidPassword(signupPassword);
            // const isValid = signupEmail && signupPassword && companyName && number &&  businessType && productsNumber && address && typeStore && singleImage && isValidEmail(signupEmail) && isValidPassword(signupPassword);
            setIsFormValid(isValid);
        } else if (activeButton === 'login') {
            const isValid = loginEmail && loginPassword && isValidEmail(loginEmail);
            setIsFormValid(isValid);
        }
    }, [signupEmail, signupPassword, loginEmail, loginPassword, activeButton, companyName, number, businessType, productsNumber, address, typeStore, singleImage]);

    
    const isValidPasswordsignup = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault(); // منع إعادة تحميل الصفحة
    
        if (!isFormValid) {
            if (activeButton === 'login') {
                setLoginError(t('fillFieldsWithValidInfo')); // ترجمة الرسالة
            } else if (activeButton === 'signup') {
                setSignupError(t('fillFieldsWithValidInfo')); // ترجمة الرسالة
            }
        } else if (activeButton === 'signup' && !isValidPasswordsignup(signupPassword)) {
            setSignupError(t('passwordRequirements')); // ترجمة الرسالة
        } else {
            setLoginError('');
            setSignupError('');
            if (activeButton === 'signup') {
                dispatch(register_vendor({
                    email: signupEmail,
                    password: signupPassword,
                    company_name: companyName, // اسم الشركة
                    phone: number, // رقم الهاتف
                    business_type: businessType, // نوع النشاط
                    products_number: productsNumber, // عدد المنتجات
                    address: address, // العنوان
                    type_store: typeStore, // نوع المتجر
                    image: singleImage.length > 0 ? singleImage[0] : null, // إذا كانت الصورة موجودة
                    role: 'supplier'
                  }))
                .unwrap()
                .then((response) => {
                    if (response.status === 200 || 201) {
                        setShowToken(true);
                        const savedData = window.localStorage.getItem('UserInfo');
                        setEmail(savedData ? JSON.parse(savedData).data?.data?.user?.email : null);
                        // window.location.reload()
                    } else {
                        // setSignupError(t('registrationFailed')); // ترجمة الرسالة
                    }
                })
                .catch((error) => {
                    console.error('Error during registration:', error);
                    setSignupError(error.message || t('generalError')); // ترجمة الرسالة
                });
            } else if (activeButton === 'login') {
                dispatch(login({
                    email: loginEmail,
                    password: loginPassword
                }))
                .unwrap()
                .then((response) => {
                    if (response.success === true) {
                        // تسجيل الدخول بنجاح
                        window.location.reload()
                    }
                })
                .catch((error) => {
                    console.error('Error during login:', error);
                    setLoginError(error);
                });
            }
        }
    };

    const verificationData = useSelector(state => state.Data.verificationData);
    console.log(verificationData)


    // هنستخدم useEffect عشان نحفظ البيانات في localStorage
    useEffect(() => {
        if (verificationData || logins) {
            const userInfo = verificationData ? verificationData : logins;
            window.localStorage.setItem('UserInfo', JSON.stringify(userInfo));
            console.log('User info saved to localStorage:', userInfo);
        }
    }, [verificationData, logins]);


    const handleOTPSubmit = async () => {
        const formattedOtp = otp.replace(/\s/g, '');
        console.log('Formatted OTP:', formattedOtp);
        
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
          setError('');
          
          const resultAction = await dispatch(verification({ email: signupEmail, verification_code: formattedOtp }));
          
          if (verification.fulfilled.match(resultAction)) {
            window.location.reload();
          } else {
            console.log('Verification failed:', resultAction.payload);
            setError('OTP verification failed. Please try again.');
          }
        } else {
          console.log('Invalid OTP:', formattedOtp);
          setError('OTP must be exactly 5 digits and must be a number.');
        }
      };

    const handleOtpChange = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtp(value.split('').join(' '));
        }
    };

    const handleIconClick = () => {
        console.log('Icon clicked, resetting all containers and clearing form values');
        
        window.localStorage.removeItem('UserInfo');
        window.localStorage.removeItem('userEmail');
    
        // إعادة تعيين جميع الحقول إلى قيم فارغة
        setSignupEmail('');
        setSignupPassword('');
        setFirstName('');
        setLastName('');
        setLoginEmail('');
        setLoginPassword('');
        setOtp('');
        setOtpReser('');
        setEmailValid('');
        setPassword('');
        setConfirmPassword('');
        setError('');
        setErrorReset('');
        setErrorResetPassword('');
        setLoginError('');
        setSignupError('');
        setLoginErrorReset('');
    
        // إعادة تعيين الأزرار والحالة
        // setActiveButton('signup'); // خليك في `signup container`
        // setActiveButton(null); 
        setShowOTP(false);
        setShowToken(false);
        setShowEmail(false);
        setShowOtpContainer(false);
        setShowPasswordContainer(false);
    };
    

    // ForgotPassword
    const sendResetData = useSelector(state => state.Data.sendReset);
    const loadingSendReset = useSelector(state => state.Data.loadingSendReset);
    const errorSendReset = useSelector(state => state.Data.errorSendReset);
    
    const verifyResetData = useSelector(state => state.Data.verifyReset);
    const loadingVerifyReset = useSelector(state => state.Data.loadingVerifyReset);
    const errorVerifyReset = useSelector(state => state.Data.errorVerifyReset);

    const resetPasswordData = useSelector(state => state.Data.resetPassword);
    const loadingresetPassword = useSelector(state => state.Data.loadingresetPassword);
    const errorresetPassword = useSelector(state => state.Data.errorresetPassword);


    const isValidEmailReset = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [emailValid, setEmailValid] = useState('');
    const [errorResetPassword, setErrorResetPassword] = useState('');
    const [loginErrorReset, setLoginErrorReset] = useState('');
    const [otpReset, setOtpReser] = useState('');
    const [errorReset, setErrorReset] = useState('');
    const [isFormValidEmail, setIsFormValidEmail] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);

    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtpContainer, setShowOtpContainer] = useState(false);
    const [showPasswordContainer, setShowPasswordContainer] = useState(false);
    const [showEmail, setShowEmail] = useState(false);

    useEffect(() => {
        const isValid = isValidEmailReset(emailValid);
        setIsFormValidEmail(isValid);
    }, [emailValid]);

    const handleSubmitEmail = () => {
        if (!isFormValidEmail) {
            setLoginErrorReset('Please fill in all fields with valid information.');
        } else {
            // حفظ البريد الإلكتروني في localStorage
            window.localStorage.setItem('userEmail', emailValid);

            dispatch(sendReset({email: emailValid }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowOtpContainer(true);
                    setShowEmail(false)
                }
            })
            .catch((error) => {

            });
        }
    };


    const [showPopup, setShowPopup] = useState(false);

    const handleSubmitPassword = () => {
        if (!Password || !confirmPassword) {
            setErrorResetPassword('يرجى إدخال كلمة المرور وتأكيدها.');
        } else if (!isValidPassword(Password)) {
            setErrorResetPassword('يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام.');
        } else if (Password !== confirmPassword) {
            setErrorResetPassword('كلمات المرور غير متطابقة.');
        } else {
            setErrorResetPassword('');
            setPasswordValid(true);
            const storedEmail = window.localStorage.getItem('userEmail');
            dispatch(resetPassword({ email: storedEmail, password: Password, password_confirmation: confirmPassword }))
                .unwrap()
                .then((response) => {
                    if (response.status === 200) {
                        setShowPasswordContainer(false);
                        setActiveButton('login');
                        setShowPopup(true); // عرض البوب أب
                        setTimeout(() => setShowPopup(false), 4000); // إخفاء البوب أب بعد 3 ثواني
                    }
                })
                .catch((error) => {
           
                });
        }
    };

    useEffect(() => {
        if (Password && confirmPassword) {
            setPasswordValid(isValidPassword(Password) && Password === confirmPassword);
        } else {
            setPasswordValid(false);
        }
    }, [Password, confirmPassword]);

    

    const handleOTPSubmitReset = () => {
        const formattedOtp = otpReset.replace(/\s/g, '');
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
            setErrorReset('');
            // استخدام البريد الإلكتروني من localStorage عند إرسال OTP
            const storedEmail = window.localStorage.getItem('userEmail');
            dispatch(verifyReset({ email: storedEmail, verification_code: formattedOtp }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowPasswordContainer(true);
                    setShowOtpContainer(false)
                    setShowEmail(false)
                }
            })
            .catch((error) => {
            
            });
        } else {
            setErrorReset('OTP must be exactly 5 digits and must be a number.');
        }
    };

    const [isOtpValid, setIsOtpValid] = useState(false);
    useEffect(() => {
        // تحقق من أن الـ OTP يتكون من 5 أرقام
        const formattedOtp = otpReset.replace(/\s/g, '');
        setIsOtpValid(formattedOtp.length === 5 && !isNaN(formattedOtp));
    }, [otpReset]);

    const handleOtpChangeReset = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtpReser(value.split('').join(' '));
        }
    };

    const showReset = () => {
        setShowEmail(true)
        // setActiveButton(null)
    };


    const onDropSingle = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
    if (imageFiles.length > 0) {
        setSingleImage(imageFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
        })));
        setErrorSingleImage('');
    } else {
        setErrorSingleImage('Please upload only images');
    }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({
    onDrop: onDropSingle,
    multiple: false
    });

  const [type, setType] = useState(["brand","store","influencer",]);
  const text5 = 'Select type'
  const[icon5, setIcon5] = useState(false)
  function handelClick5() {
    setIcon5(!icon5)
  }

    return (
        <div className="Register">
            <div className='signup px-3 px-md-0'>
      <>

        {activeButton === 'signup' &&
          <div className='row '>
            <div className="cont col-6">
              {loadingRegister_vendor && <Loader />}
              <div className='createAccount mt-2 d-flex justify-content-between'>
                <img src={group} alt="group" />
                <p className='font-size2'>{t('createAccount')}</p>
              </div>
                <form onSubmit={handleSubmit}>

                    <div className='div3'>
                        <div>
                        <input
                            placeholder={t('enterEmail')}
                            onChange={(e) => setSignupEmail(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='div4'>
                        <div>
                        <input
                            placeholder={t('password')}
                            type="password"
                            onChange={(e) => setSignupPassword(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='div4'>
                        <div>
                        <input
                            placeholder='Company Name'
                            type="text"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                        />
                        </div>
                    </div>
                    <div className='div4'>
                    <input type='text' id='number' name='number' value={number} onChange={(e) => setNumber(e.target.value)} placeholder='Phone'/>
                    </div>

                    <div className='div4'>
                        <div>
                        <input
                            placeholder='Your type of activity'
                            type="text"
                            onChange={(e) => setBusinessType(e.target.value)}
                            value={businessType}
                        />
                        </div>
                    </div>
                    <div className='div_lest'>
                        <p className='font-size1'>{t('eightCharsMin')}</p>
                        <p className='font-size1'>{t('oneLetter')}</p>
                        <p className='font-size1'>{t('oneNumber')}</p>
                    </div>
                    {activeButton === 'signup' && signupError && <div className='error-message'>{signupError}</div>}
                    {/* {errorRegister?.message && <div className='error-message'>{errorRegister?.message}</div>} */}
                    <div className='div6 mt-2'>
                        <div>
                        <button
                            className={isFormValid ? 'continue-button valid' : 'continue-button'}
                            type="submit"
                        >
                            {t('CONTINUE')}
                        </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className='col-6 cont'>
            {loadingRegister_vendor && <Loader />}
                <div className='div10 mt-4 mb-4'>
                    <p
                    className={activeButton === 'login' ? 'active' : ''}
                    onClick={() => handleButtonClick('login')}
                    >
                    {t('login')}
                    </p>
                    <p
                    className={activeButton === 'signup' ? 'active' : ''}
                    onClick={() => handleButtonClick('signup')}
                    >
                    {t('signup')}
                    </p>
                </div>

                <div className='div4'>
                    <div>
                    <input
                        placeholder='The number of products you will upload'
                        type="number"
                        onChange={(e) => setProductsNumber(e.target.value)}
                        value={productsNumber}
                    />
                    </div>
                </div>

                <div className="box_AddCompany div4" onClick={handelClick5}>
                    <div className="heade">
                        <span>{typeStore ? typeStore : text5}</span>
                        <i><FontAwesomeIcon icon={faChevronRight}/></i>
                    </div>
                    {icon5 &&
                        <div className="cat">
                            {type.map((e, i) => 
                                <p onClick={() => (setTypeStore(e), setIcon5(!icon5))}>{e}</p>
                            )}
                        </div>
                    }
                </div>


                <div className='div12'>
                    <textarea
                    placeholder="the address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    />
                </div>

                {/* Image */}
                <div className="div1">
                    <div className="Add_img m-auto mt-0">
                    {errorSingleImage && <p className="error">{errorSingleImage}</p>}
                    <div className="adds" {...getRootPropsSingle()}>
                        <input {...getInputPropsSingle()} />
                        {!singleImage.length ? (
                        <div className="upload-placeholder">
                            <i className="fa fa-cloud-upload" />
                            <p>Click here to download the image</p>
                        </div>
                        ) : (
                        <div className="image-preview single">
                            {singleImage.map((file, index) => (
                            <img key={index} src={file.preview} alt={`upload preview ${index}`} className="img" />
                            ))}
                        </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>
          </div>
        }
      </>
            </div>
            {showToken && (
                <div className='container'>
                    <div className='row container_otp'>
                        <div className="cont col-4 ">
                            {loadingVerification && <Loader />}
                            <div className='icon'>
                                <i className='font-size2 ' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group" />
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('VERIFY YOUR EMAIL ADDRESS')}</p>
                            </div>
                            <div className='div13'>
                                <p className='font-size1'>{t('Verify your email using the OTP sent to your email address')}</p>
                            </div>

                            {error && <div className='error-message'>{error}</div>}
                            <div className='error-message'>{messageOTP?.message}</div>
                            <div className='div15'>
                                <p>{t('OTP')}</p>
                                <input
                                    className="otp-input"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    placeholder="0 0 0 0 0"
                                    maxLength="9" // Maximum length including spaces
                                />
                            </div>
                            
                            <div className='div6'>
                                <div>
                                    <p
                                        className='continue-button valid'
                                        onClick={handleOTPSubmit}
                                    >
                                        {t('CONTINUE')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className='login col-12'>
                {activeButton === 'login' && (
                    <div className='row login_activ col-12 g-0'>
                        <div className="cont col-6">
                            {loadingLogin && <Loader />}
                            <div className='logo_dash mt-5'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('Create your account')}</p>
                            </div>
                            <div className='div10'>
                                <p
                                    className={activeButton === 'login' ? 'active' : ''}
                                    onClick={() => handleButtonClick('login')}
                                >
                                    {t('Log in')}
                                </p>
                                <p
                                    className={activeButton === 'signup' ? 'active' : ''}
                                    onClick={() => handleButtonClick('signup')}
                                >
                                    {t('Sign up')}
                                </p>
                            </div>
                            <form onSubmit={handleSubmit}>

                                <div className='div3'>
                                    <div>
                                        <input
                                            placeholder={t('Please enter email')}
                                            value={loginEmail}
                                            onChange={(e) => setLoginEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='div4'>
                                    <div>
                                        <input
                                            placeholder={t('Password')}
                                            type="password"
                                            value={loginPassword}
                                            onChange={(e) => setLoginPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='div5'>
                                    <p className='font-size1' onClick={showReset}>{t('Forgot Password')}</p>
                                </div>
                                {activeButton === 'login' && loginError && <div className='error-message'>{loginError}</div>}
                                {activeButton === 'signup' && signupError && <div className='error-message'>{signupError}</div>}

                                {errorLogin?.message?.password && <div className='error-message'>{errorLogin?.message?.password }</div>}

                                <div className='div6 mt-5'>
                                    <div>
                                        <button
                                            className={isFormValid ? 'continue-button valid' : 'continue-button'}
                                            type="submit"
                                        >
                                            {t('CONTINUE')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className=" col-6 container_img">
                            <img src={image2}/>
                        </div>
                    </div>
                )}
            </div>

            {/* ForgotPassword */}
            {showEmail && (
                <div className='reset container'>
               <div className='row container_otp'>
               <div className="cont col-4 ">
                            {loadingSendReset && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div3 email mt-5'>
                                <div>
                                    <input
                                        placeholder={t('Please enter email')}
                                        onChange={(e) => setEmailValid(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mt mt-4'>
                                <div className='error-message'>{loginErrorReset}</div>
                                <div className='error-message'>{errorSendReset?.message?.message}</div>
                                <div className='div6'>
                                    <div>
                                        <p
                                            className={isFormValidEmail ? 'continue-button valid' : 'continue-button'}
                                            onClick={handleSubmitEmail}
                                        >
                                            {t('CONTINUE')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showOtpContainer && (
                <div className='otp container'>
                    <div className='row container_otp'>
                        <div className="cont col-4 ">                
                            {loadingVerifyReset && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group" />
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('VERIFY YOUR EMAIL ADDRESS')}</p>
                            </div>
                            <div className='div13'>
                                <p className='font-size1'>{t('Verify your email using the OTP sent to your email address')}</p>
                            </div>
                            <div className='div14'>
                                <p>{t('Resend OTP')}</p>
                            </div>
                            <div className='div15'>
                                <p>{t('OTP')}</p>
                                <input
                                    className="otp-input"
                                    value={otpReset}
                                    onChange={handleOtpChangeReset}
                                    placeholder="0 0 0 0 0"
                                    maxLength="9"
                                />
                            </div>

                            {otpReset && <div className='error-message'>{errorReset}</div>}
                            <div className='error-message'>{errorVerifyReset?.message}</div>

                            <div className='div6'>
                                <div>
                                    <p
                                        className={`continue-button ${isOtpValid ? 'valid' : ''}`}
                                        onClick={handleOTPSubmitReset}
                                    >
                                        {t('CONTINUE')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showPasswordContainer && (
                <div className='reset container'>
                    <div className='row container_otp'>
                        <div className="cont col-4 ">                       
                            {loadingresetPassword && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('Create your password')}</p>
                            </div>

                            <div className='div3 email'>
                                <div>
                                    <input
                                        placeholder={t('Password')}
                                        type="password"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='div3 email'>
                                <div>
                                    <input
                                        placeholder={t('Confirm Password')}
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className='error-message'>{errorResetPassword}</div>
                                <div className='error-message'>{errorresetPassword?.message}</div>
                                <div className='div6'>
                                    <div>
                                        <p
                                        className={`continue-button ${isPasswordValid ? 'valid' : ''}`}
                                            onClick={handleSubmitPassword}
                                        >
                                            {t('CONTINUE')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <div className="popup_log">
                    <div className="popup-icon">
                        <i><FontAwesomeIcon icon={faCheck}/></i>
                    </div>
                    <p>{t('Password changed successfully')}</p>
                    <p>{t('Please log in now.')}</p>
                </div>
            )}

                    </div>
                );
            }

export default Register;
