import './Categoires.css';
import './CategorysVendors.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faPlus, faTags, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import img1 from './images/88209500.jpg';
import img2 from './images/Asset 19.png';
import img3 from './images/Asset 22.png';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { category, delete_category } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';
import { get_customers } from "./Redux/API";


function CategorysVendors() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const loadingDelete_category = useSelector(state => state.Data.loadingDelete_category);
  const loadingCategory = useSelector(state => state.Data.loadingCategory);
  const categoryS = useSelector(state => state.Data.category);


  useEffect(() => {
    dispatch(category())
  }, [dispatch, loadingDelete_category])

  const gotoAddCategoire = () => {
    navigate('/AddCategoire');
  }
  const goBack = () => {
    navigate(-1);
  }

  const listOrders = t('categoires.column', { returnObjects: true });
  const [showProdcts, setShowProdcts] = useState(null);
  const toggleShowProducts = (id) => setShowProdcts(showProdcts === id ? null : id);


  const handleDeleteCategoires = (id) => {
    dispatch(delete_category(id));
  };

  return (
    <div className="Categoires">
      <Header />
      <div className='container-fluid'>
        {loadingDelete_category && <Loader />}
        {loadingCategory && <Loader />}

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('categoires.search_placeholder')} />
              <div className='AddCat' onClick={gotoAddCategoire}>
                <span><FontAwesomeIcon icon={faPlus} /></span>
                <span>{t('categoires.add_categoire')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-5'>
          <div className='Orders'>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>
<>
            {categoryS?.data?.data.map((item) => (
                <div key={item.id}>
                    <div
                        className='Orders_border row justify-content-around cursor-defaul'
                        onClick={(() => toggleShowProducts(item.id))}
                        >
                        <p className='col-2 text-center'>{item.id}</p>
                        <p className='col-2 text-center'><img src={item.image} alt={item.id}/></p>
                        <p className='col-2 text-center'>{item.name}</p>
                    </div>

                </div>
              ))}
            </>



          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorysVendors;