import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { get_revenues } from './Redux/API';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RevenueComparisonChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const get_revenue = useSelector((state) => state?.Data?.get_revenues || {});
  const loadingGet_revenues = useSelector((state) => state?.Data?.loadingGet_revenues);

  const [selectedDate, setSelectedDate] = useState('');
  const [comparisonDate, setComparisonDate] = useState('');
  const [timeRange, setTimeRange] = useState();

  const handleDateChange = (event) => setSelectedDate(event.target.value);
  const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);

  const handleSubmit = () => {
    if (selectedDate && comparisonDate) {
      dispatch(get_revenues({ start_date: selectedDate, comparison_date: comparisonDate }));
    }
  };

  const handleTimeRangeChange = (range) => setTimeRange(range);

  const selectedYear = selectedDate.split('-')[0];
  const selectedMonth = selectedDate.split('-')[1];
  const comparisonYear = comparisonDate.split('-')[0];
  const comparisonMonth = comparisonDate.split('-')[1];

  const getDataByTimeRange = (year, month) => {
    const yearData = get_revenue?.[year];
    const monthData = yearData?.[month];
    return monthData ? monthData[timeRange] || [] : [];
  };

  const selectedData = getDataByTimeRange(selectedYear, selectedMonth);
  const comparisonData = getDataByTimeRange(comparisonYear, comparisonMonth);

  const chartConfig = {
    labels: Array.from({ length: Math.max(selectedData.length, comparisonData.length) }, (_, i) => `Period ${i + 1}`),
    datasets: [
      {
        label: `${t('revenueChart.revenue')} ${selectedYear} (${selectedMonth}) - ${t(`revenueChart.${timeRange}`)}`,
        data: selectedData,
        fill: false,
        backgroundColor: '#3795BD',
        borderColor: '#3A1078',
      },
      {
        label: `${t('revenueChart.revenue')} ${comparisonYear} (${comparisonMonth}) - ${t(`revenueChart.${timeRange}`)}`,
        data: comparisonData,
        fill: false,
        backgroundColor: '#FFB22C',
        borderColor: '#FF4C4C',
      },
    ],
  };

  return (
    <div className='row'>
      {loadingGet_revenues && <Loader />}
      <div className='col-6'>
        <label>{t('revenueChart.selectDate')}</label>
        <input
          className='w-100 m-0 mb-3'
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>

      <div className='col-6'>
        <label>{t('revenueChart.comparisonDate')}</label>
        <input
          className='w-100 m-0 mb-3'
          type="date"
          value={comparisonDate}
          onChange={handleComparisonDateChange}
        />
      </div>

      <div className='m d-flex justify-content-between col-12'>
        <div className='col-4'>
          <button onClick={handleSubmit} className='btn btn-primary'>
            {t('revenueChart.displayData')}
          </button>
        </div>
        <div className='col-5'>
          <div className='btn-group mb-3'>
            <button 
              className={`btn ${timeRange === 'day' ? 'btn-primary' : 'btn-outline-primary'}`} 
              onClick={() => handleTimeRangeChange('day')}
            >
              {t('revenueChart.day')}
            </button>
            <button 
              className={`btn ${timeRange === 'week' ? 'btn-primary' : 'btn-outline-primary'}`} 
              onClick={() => handleTimeRangeChange('week')}
            >
              {t('revenueChart.week')}
            </button>
            <button 
              className={`btn ${timeRange === 'month' ? 'btn-primary' : 'btn-outline-primary'}`} 
              onClick={() => handleTimeRangeChange('month')}
            >
              {t('revenueChart.month')}
            </button>
            <button 
              className={`btn ${timeRange === 'year' ? 'btn-primary' : 'btn-outline-primary'}`} 
              onClick={() => handleTimeRangeChange('year')}
            >
              {t('revenueChart.year')}
            </button>
          </div>
        </div>
      </div>

      <div className='col-12'>
        <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          {selectedData.length === 0 && comparisonData.length === 0 ? (
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>{t('revenueChart.noData')}</p>
          ) : (
            <Line data={chartConfig} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenueComparisonChart;

// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { useDispatch, useSelector } from 'react-redux';
// import { get_revenues } from './Redux/API';
// import { useTranslation } from 'react-i18next';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const RevenueComparisonChart = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const get_revenue = useSelector((state) => state?.Data?.get_revenues || {});
//   const loadingGet_revenues = useSelector((state) => state?.Data?.loadingGet_revenues);

//   const [selectedDate, setSelectedDate] = useState('2023-01-01');
//   const [comparisonDate, setComparisonDate] = useState('2022-01-01');
//   const [timeRange, setTimeRange] = useState('day');

//   const handleDateChange = (event) => setSelectedDate(event.target.value);
//   const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);

//   const handleSubmit = () => {
//     if (selectedDate && comparisonDate) {
//       dispatch(get_revenues({ start_date: selectedDate, comparison_date: comparisonDate }));
//     }
//   };

//   const handleTimeRangeChange = (range) => {
//     setTimeRange(range);
//   };

//   const selectedYear = selectedDate.split('-')[0];
//   const selectedMonth = selectedDate.split('-')[1];
//   const comparisonYear = comparisonDate.split('-')[0];
//   const comparisonMonth = comparisonDate.split('-')[1];

//   const getDataByTimeRange = (year, month) => {
//     const yearData = get_revenue?.[year];
//     const monthData = yearData?.[month];
//     return monthData ? monthData[timeRange] || [] : [];
//   };

//   const selectedData = getDataByTimeRange(selectedYear, selectedMonth);
//   const comparisonData = getDataByTimeRange(comparisonYear, comparisonMonth);

//   const chartConfig = {
//     labels: Array.from({ length: Math.max(selectedData.length, comparisonData.length) }, (_, i) => `الفترة ${i + 1}`),
//     datasets: [
//       {
//         label: `إيرادات ${selectedYear} (${selectedMonth}) - ${timeRange}`,
//         data: selectedData,
//         fill: false,
//         backgroundColor: '#3795BD',
//         borderColor: '#3A1078',
//       },
//       {
//         label: `إيرادات ${comparisonYear} (${comparisonMonth}) - ${timeRange}`,
//         data: comparisonData,
//         fill: false,
//         backgroundColor: '#FFB22C',
//         borderColor: '#FF4C4C',
//       },
//     ],
//   };

//   return (
//     <div className='row'>
//       <div className='col-6'>
//         <label>اختر التاريخ لعرض البيانات</label>
//         <input
//           className='w-100 m-0 mb-3'
//           type='date'
//           value={selectedDate}
//           onChange={handleDateChange}
//         />
//       </div>

//       <div className='col-6'>
//         <label>اختر تاريخ المقارنة</label>
//         <input
//           className='w-100 m-0 mb-3'
//           type='date'
//           value={comparisonDate}
//           onChange={handleComparisonDateChange}
//         />
//       </div>
// <div className='m d-flex justify-content-between col-12'>
//       <div className='col-4'>
//         <button onClick={handleSubmit} className='btn btn-primary'>
//           عرض البيانات
//         </button>
//       </div>

//       <div className='col-5'>
//         <div className='btn-group mb-3'>
//           <button 
//             className={`btn ${timeRange === 'day' ? 'btn-primary' : 'btn-outline-primary'}`} 
//             onClick={() => handleTimeRangeChange('day')}
//           >
//             اليوم
//           </button>
//           <button 
//             className={`btn ${timeRange === 'week' ? 'btn-primary' : 'btn-outline-primary'}`} 
//             onClick={() => handleTimeRangeChange('week')}
//           >
//             الأسبوع
//           </button>
//           <button 
//             className={`btn ${timeRange === 'month' ? 'btn-primary' : 'btn-outline-primary'}`} 
//             onClick={() => handleTimeRangeChange('month')}
//           >
//             الشهر
//           </button>
//           <button 
//             className={`btn ${timeRange === 'year' ? 'btn-primary' : 'btn-outline-primary'}`} 
//             onClick={() => handleTimeRangeChange('year')}
//           >
//             السنة
//           </button>
//         </div>
//       </div>

//       </div>

//       <div className='col-12 mt-4'>
//         <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
//           {selectedData.length === 0 && comparisonData.length === 0 ? (
//             <p style={{ textAlign: 'center', paddingTop: '20px' }}>لا توجد بيانات لعرضها!</p>
//           ) : (
//             <Line data={chartConfig} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RevenueComparisonChart;





// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { useDispatch, useSelector } from 'react-redux';
// import { get_revenues } from './Redux/API';
// import { useTranslation } from 'react-i18next';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const RevenueComparisonChart = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const get_revenue = useSelector((state) => state?.Data?.get_revenues || {}); // ضمان وجود كائن فارغ
//   const loadingGet_revenues = useSelector((state) => state?.Data?.loadingGet_revenues);



// console.log(get_revenue)
// console.log('5555555555555555555544444444444444444444777777777777')

//   const [selectedDate, setSelectedDate] = useState('2023-01-01');
//   const [comparisonDate, setComparisonDate] = useState('2022-01-01');
//   const [timeRange, setTimeRange] = useState('day');

//   const handleDateChange = (event) => setSelectedDate(event.target.value);
//   const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);
//   const handleTimeRangeChange = (event) => setTimeRange(event.target.value);

//   const selectedYear = selectedDate.split('-')[0];
//   const selectedMonth = selectedDate.split('-')[1];

//   const comparisonYear = comparisonDate.split('-')[0];
//   const comparisonMonth = comparisonDate.split('-')[1];

//   // دالة لإرسال البيانات للباك اند عند الضغط على الزر
//   const handleSubmit = () => {
//     if (selectedDate && comparisonDate) {
//       dispatch(get_revenues({ start_date: selectedDate, comparison_date: comparisonDate }));
//     }
//   };

//   // استخراج البيانات من get_revenue بناءً على التاريخ والفترة الزمنية
//   const getDataByTimeRange = (year, month) => {
//     const yearData = get_revenue?.[year]; // تحقق من وجود بيانات للسنة
//     const monthData = yearData?.[month]; // تحقق من وجود بيانات للشهر
//     return monthData ? monthData[timeRange] || [] : []; // إرجاع بيانات الفترة أو مصفوفة فارغة
//   };

//   const selectedData = getDataByTimeRange(selectedYear, selectedMonth);
//   const comparisonData = getDataByTimeRange(comparisonYear, comparisonMonth);

//   const chartConfig = {
//     labels: Array.from({ length: Math.max(selectedData.length, comparisonData.length) }, (_, i) => `الفترة ${i + 1}`),
//     datasets: [
//       {
//         label: `إيرادات ${selectedYear} (${selectedMonth}) - ${timeRange}`,
//         data: selectedData,
//         fill: false,
//         backgroundColor: '#3795BD',
//         borderColor: '#3A1078',
//       },
//       {
//         label: `إيرادات ${comparisonYear} (${comparisonMonth}) - ${timeRange}`,
//         data: comparisonData,
//         fill: false,
//         backgroundColor: '#FFB22C',
//         borderColor: '#FF4C4C',
//       },
//     ],
//   };

//   return (
//     <div className='row'>
//       <div className='col-6'>
//         <label>اختر التاريخ لعرض البيانات</label>
//         <input
//           className='w-100 m-0 mb-3'
//           type='date'
//           value={selectedDate}
//           onChange={handleDateChange}
//         />
//       </div>

//       <div className='col-6'>
//         <label>اختر تاريخ المقارنة</label>
//         <input
//           className='w-100 m-0 mb-3'
//           type='date'
//           value={comparisonDate}
//           onChange={handleComparisonDateChange}
//         />
//       </div>

//       <div className='col-12'>
//         <label>اختر الفترة الزمنية</label>
//         <select
//           className='w-100 m-0 mb-3 h-50'
//           value={timeRange}
//           onChange={handleTimeRangeChange}
//         >
//           <option value='day'>اليوم</option>
//           <option value='week'>الأسبوع</option>
//           <option value='month'>الشهر</option>
//           <option value='year'>السنة</option>
//         </select>
//       </div>

//       {/* زرار لإرسال البيانات */}
//       <div className='col-12'>
//         <button onClick={handleSubmit} className='btn btn-primary'>
//           عرض البيانات
//         </button>
//       </div>

//       <div className='col-12 mt-4'>
//         <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
//           {selectedData.length === 0 && comparisonData.length === 0 ? (
//             <p style={{ textAlign: 'center', paddingTop: '20px' }}>لا توجد بيانات لعرضها!</p>
//           ) : (
//             <Line data={chartConfig} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RevenueComparisonChart;