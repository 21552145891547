import React, { useState, useEffect } from "react";
import './AddAllCoupon.css';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { create_coupon, all_coupon, delete_coupon, all_discount } from "./Redux/API";
import Loader from './Loader';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddAllCoupon = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const loadingCreate_coupon = useSelector(state => state.Data.loadingCreate_coupon);
  const create_coupons = useSelector(state => state.Data.create_coupon);
  
  const loadingAll_coupon = useSelector(state => state.Data.loadingAll_coupon);
  const loadingDelete_coupon = useSelector(state => state.Data.loadingDelete_coupon);
  const all_coupons = useSelector(state => state.Data.all_coupon);

  useEffect(() => {
    dispatch(all_coupon());
  }, [dispatch, loadingCreate_coupon, loadingDelete_coupon]);
  
  const [formData, setFormData] = useState({
    discount_id: "", // تم تعديل هذا الجزء لإرسال ID الخصم
    code: "",
    usage_limit: "",
    expiry_date: "",
  });

  const [errors, setErrors] = useState(""); 
console.log(formData)
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const { discount_id, code, usage_limit, expiry_date } = formData;
    if (!discount_id || !code || !usage_limit || !expiry_date) {
      return false; 
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      dispatch(create_coupon(formData));  // إرسال الـ formData الذي يحتوي على ID الخصم
  
      setFormData({
        discount_id: "",  // إعادة تعيين الحقول
        code: "",
        usage_limit: "",
        expiry_date: "",
      });
  
      setErrors("");
    } else {
      setErrors(t('errors.fill_all_fields')); // رسالة الخطأ
    }
  };
  
  const allCoupon = [t('coupon.discount_value'), t('coupon.code'), t('coupon.usage_limit'), t('coupon.expiry_date'), t('coupon.action')];

  const handleDeleteCoupon = (id) => {
    dispatch(delete_coupon(id));
  };


  const [selectedCouponId, setSelectedCouponId] = useState("");  // حفظ الـ ID المختار
  const handleCouponChange = (e) => {
    const selectedId = e.target.value;
    setSelectedCouponId(selectedId);
    setFormData({
      ...formData,
      discount_id: selectedId  // تحديث discount_id بدلاً من القيمة
    });
  };

  useEffect(() => {
    dispatch(all_discount());
  }, [dispatch]);
  
  const all_discounts = useSelector(state => state.Data.all_discount);


  return (
    <div className="AddAllCoupon">
      <Header />
      <div className='row align-items-center'>
        <div className='name_header d-flex'></div>
        <div className="container icon"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="container">
          {loadingDelete_coupon && <Loader />}
          {loadingAll_coupon && <Loader />}
          {loadingCreate_coupon && <Loader />}
          <div className="row"> 
            <div className="form-group w-50 mt-4">
              <label>{t('coupon.discount_value')}</label>
              <select 
                id="couponSelect"
                className="form-select select-coupons"
                value={selectedCouponId} 
                onChange={handleCouponChange}  // استدعاء الدالة عند التغيير
              >
                <option value="">{t('select_coupon')}</option>
                {all_discounts?.data?.data?.discounts.map(coupon => (
                  <option key={coupon.id} value={coupon.id}>
                    {`Name ${coupon.name}`} &nbsp;&nbsp;&nbsp;&nbsp; {`Discount ${coupon.discount_value}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="Coupon">
              <label>{t('coupon.code')}</label>
              <input
                type="text"
                name="code"
                value={formData.code}
                onChange={handleChange}
              />
            </div>
            <div className="Coupon">
              <label>{t('coupon.usage_limit')}</label>
              <input
                type="number"
                name="usage_limit"
                value={formData.usage_limit}
                onChange={handleChange}
              />
            </div>
            <div className="Coupon">
              <label>{t('coupon.expiry_date')}</label>
              <input
                type="datetime-local"
                name="expiry_date"
                value={formData.expiry_date}
                onChange={handleChange}
              />
            </div>
            <button className="button" type="submit">{t('actions.submit')}</button>
          </div>
        </div>
      </form>

      {errors && <p style={{ color: "red" }}>{errors}</p>}

      <div className='container'>
        <div className='Orders px-3 mt-5'>
          <div className='Orders_border row justify-content-around'>
            {allCoupon.map((e, index) => (
              <p className='col-2 text-center border-0' key={index}>{e}</p>
            ))}
          </div>

          {all_coupons?.data?.data?.coupons.map((order) => (
            <div key={order.id}>
              <div className='div_Transactions d-flex justify-content-around'>
                <p className='col-2 text-center'>{order.discount_id}</p>
                <p className='col-2 text-center'>{order.code}</p>
                <p className='col-2 text-center'>{order.usage_limit}</p>
                <p className='col-2 text-center'>{order.expiry_date}</p>
                <p className='col-2 text-center'>
                  <span 
                    className='span_icon2' 
                    onClick={() => handleDeleteCoupon(order.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddAllCoupon;