import './ProductsList.css';
import './AddCoupon.css';
import './AddDiscount.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { product, add_discount, searsh_product , all_discount} from "./Redux/API";
import Loader from './Loader';
import Header from './Header/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function AddDiscount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const products = useSelector(state => state.Data.product);
  const loadingProduct = useSelector(state => state.Data.loadingProduct);
  
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCouponId, setSelectedCouponId] = useState(""); // Store coupon ID
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const loadingAll_coupon = useSelector(state => state.Data.loadingAll_coupon);
  const loadingAdd_discount = useSelector(state => state.Data.loadingAdd_discount);
  const all_discounts = useSelector(state => state.Data.all_discount);

  const searsh_products = useSelector(state => state.Data.searsh_product);
  const loadingSearsh_product = useSelector(state => state.Data.loadingSearsh_product);

  useEffect(() => {
    dispatch(all_discount());
  }, [dispatch]);


  useEffect(() => {
    dispatch(product());
  }, [dispatch, loadingAdd_discount]);

  const handleSelectProduct = (e, productId) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    }
  };

  

  const handleCouponChange = (e) => {
    setSelectedCouponId(e.target.value);
  };

  const handleSubmit = () => {
    if (!selectedCouponId && selectedProducts.length === 0) {
      setErrorMessage(t('errors.select_coupon_and_products')); 
    } else if (!selectedCouponId) {
      setErrorMessage(t('errors.select_coupon')); 
    } else if (selectedProducts.length === 0) {
      setErrorMessage(t('errors.select_products'));
    } else {
      setErrorMessage(""); 
      dispatch(add_discount({
        product_ids: selectedProducts,
        discount_id: selectedCouponId // Send the coupon ID
      }));
    }
  };

  const listOrders = [t('products_list.product_name'), t('products_list.category'), t('products_list.price'), t('products_list.brand'), t('products_list.discount')];

    // **حالة البحث**
    const [searshBroduct, setSearshBroduct] = useState('');
    const handleInputChangeReturns = (e) => {
      setSearshBroduct(e.target.value); 
    };
  
    useEffect(() => {
        dispatch(searsh_product(searshBroduct)); // استدعاء البحث
    }, [searshBroduct, dispatch]);


  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faHeart} /></i>
            <p>{t('favorites')}</p>
          </div>
        </div>

        <div className='container mt-3'>
          <div className='row'>
            {errorMessage && (
              <p className="error-message text-center" style={{ color: 'red' }}>{errorMessage}</p> 
            )}
            <div className='sarsh justify-content-between d-flex mt-3 mb-3'>

              <div className='sarsh col-5'>
                <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} placeholder={t('products_list.search')} />
              </div>

              <div>
                <div className="form-group w-100">
                <select 
                  id="couponSelect"
                  className="form-select select-coupons w-100"
                  value={selectedCouponId} 
                  onChange={handleCouponChange}
                >
                  <option value="">Select Discount</option>
                  {all_discounts?.data?.data?.discounts.map(coupon => (
                    <option key={coupon.id} value={coupon.id} className="coupon-option">
                      <span className="coupon-name">{`name: ${coupon.name}`}</span>
                      &nbsp;&nbsp;&nbsp;&nbsp; 
                      <span className="coupon-discount">{`Discount: ${coupon.discount_value}`}</span>
                    </option>           
                  ))}
                </select>
              </div>
                <button onClick={handleSubmit}>{t('actions.apply_discount')}</button>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          {loadingProduct && <Loader />}
          {loadingAdd_discount && <Loader />}
          {loadingSearsh_product && <Loader />}
          <div className='Orders'>
            <div className='Orders_border row justify-content-between'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center mb-3' key={index}>{e}</p>
              ))}
            </div>

            {searshBroduct
            ? (
                // عرض نتائج البحث
                searsh_products?.data?.data.map((item) => (
                  <div key={item.id}>
                  <div className='Orders_border row align-items-center justify-content-between'>
                    <input className='checkbox_discount'
                      type="checkbox" 
                      onChange={(e) => handleSelectProduct(e, item.id)} 
                    />
                    <p className='col-2 text-center'><span>{item.name}</span></p>
                    <p className='col-2 text-center'>{item.category}</p>
                    <p className='col-2 text-center'>{item.new_price}</p>
                    <p className='col-2 text-center'>{item.brand}</p>
                    <p className='col-2 text-center'>{item.discount}</p>
                  </div>
                </div>
                ))
              )
            : (
                // عرض المنتجات المؤرشفة (الأساسية) عندما يكون البحث فارغاً
                products?.data?.data.map((item) => (
                  <div key={item.id}>
                  <div className='Orders_border row align-items-center justify-content-between'>
                    <input className='checkbox_discount'
                      type="checkbox" 
                      onChange={(e) => handleSelectProduct(e, item.id)} 
                    />
                    <p className='col-2 text-center'><span>{item.name}</span></p>
                    <p className='col-2 text-center'>{item.category}</p>
                    <p className='col-2 text-center'>{item.new_price}</p>
                    <p className='col-2 text-center'>{item.brand}</p>
                    <p className='col-2 text-center'>{item.discount}</p>
                  </div>
                </div>
                ))
              )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDiscount;