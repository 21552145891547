import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './CustomerReports.css';
import Header from './Header/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customers_report } from "./Redux/API";
import Loader from './Loader';
import { CustomerDetails } from "./Redux/HomeSlice";

const CustomerReports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoDataCustomers = (user) => {
    dispatch(CustomerDetails(user));
    navigate('/DataCustomers');
  };

  const customers_reports = useSelector(state => state.Data.customers_report);
  const loadingCustomers_report = useSelector(state => state.Data.loadingCustomers_report);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(customers_report());
      } catch (error) {
        console.error('Error fetching customer reports:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <div className='CustomerReports'>
      <Header />
      <div className='container-fluid'>
        {loadingCustomers_report && <Loader />}
        <h5>{t('reports.title')}</h5>
        <div className='row'>
          <div className='col-5'>
            <h5>{t('reports.title')}</h5>
            <p>{t('reports.new_customers')}: <span>{customers_reports?.data?.data?.new_customers}</span></p>
            <p>{t('reports.repeat_customers')}: <span>{customers_reports?.data?.data?.repeat_customers}</span></p>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <h5 className='font- mt-3'>{t('reports.high_value_customers')}</h5>
            <div className='Orders_border row justify-content-around'>
              {t('customers.columns2', { returnObjects: true }).map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {customers_reports?.data?.data?.high_value_customers?.length === 0 ? (
              <p>{t('reports.no_high_value_customers')}</p>
            ) : (
              customers_reports?.data?.data?.high_value_customers.map((customer, index) => (
                <div key={index} className='Orders_border row justify-content-around' onClick={() => gotoDataCustomers(customer)}>
                  <p className='aa col-3'>
                    <span>{customer.first_name}</span>
                  </p>
                  <p className='col-3 text-center'>{customer.phone_number}</p>
                  <p className='col-3 text-center'>{customer.last_login_date || 'N/A'}</p>
                  <p className='col-3 text-center'>{customer.email || 'N/A'}</p>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReports;
