import { createSlice } from '@reduxjs/toolkit';
// import { offers } from './API';

export const HomeSlice = createSlice({
  name: 'products',
  initialState: {
    favoritse: [],
    Customer: null,
    Category: null,
    
  },
  reducers: {
    addFavorites: (state, action) => {
      state.favoritse = action.payload.favoritse;
    },
    CustomerDetails: (state, action) => {
      state.product = action.payload;

    },
    EditCategory: (state, action) => {
      state.editC = action.payload;

    },
    EditAd: (state, action) => {
      state.ads = action.payload;

    },
    EditProduct: (state, action) => {
      state.productD = action.payload;

    },
    
  },
  
});

export const {addFavorites, CustomerDetails, EditCategory, EditAd, EditProduct} = HomeSlice.actions;
export default HomeSlice.reducer;
