import React, { useState, useCallback, useEffect } from "react";
import './AddCategoire.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCartPlus, faCheck, faChevronRight, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { post_category, edit_category } from "./Redux/API";
import Loader from './Loader';

function AddCategoire() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Category = useSelector(state => state?.products.editC);
    const categoryList = useSelector(state => state.Data.category);
    const loadingPost_category = useSelector(state => state.Data.loadingPost_category);

    const [taskNameAr, setTaskNameAr] = useState('');
    const [taskNameEn, setTaskNameEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [singleImage, setSingleImage] = useState(null);
    const [parentId, setParentId] = useState(null);
    const [activeCategory, setActiveCategory] = useState(null);
    const [showBox, setShowBox] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (Category) {
            setTaskNameAr(Category.name || '');
            setTaskNameEn(Category.nameEn || '');
            setDescriptionAr(Category.descriptionAr || '');
            setDescriptionEn(Category.descriptionEn || '');
            setSingleImage(Category.image || null);
            setParentId(Category.parent_id || null);

            const foundCategory = categoryList?.data?.data.find(cat => cat.id === Category.parent_id);
            setActiveCategory(foundCategory ? foundCategory.name : null);
        }
    }, [Category, categoryList]);

    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSingleImage(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });

    const handleToggle = (id, name) => {
        setParentId(id);
        setActiveCategory(name);
        setShowBox(false);
    };
    

    console.log(Category)
    const uploadTask = (e) => {
        e.preventDefault();

        if (!taskNameAr || !taskNameEn || !descriptionEn || !descriptionAr || !singleImage) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

        if (Category) {
            dispatch(edit_category({
                id: Category.id,
                taskNameAr,
                taskNameEn,
                descriptionAr,
                descriptionEn,
                singleImage,
                parentId
            }));
        } else {
            dispatch(post_category({
                taskNameAr,
                taskNameEn,
                descriptionAr,
                descriptionEn,
                singleImage,
                parentId,
            }));
        }

        // Reset fields after submission
        setTaskNameAr('');
        setTaskNameEn('');
        setDescriptionAr('');
        setDescriptionEn('');
        setSingleImage(null);
        setParentId(null);
        setActiveCategory(null);
    };

    return (
        <div className="AddCategoire">
            <Header />
            <div className="container">
                {loadingPost_category && <Loader />}
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i><FontAwesomeIcon icon={faCartPlus} /></i>
                        <p>{t('addCategory.title')}</p>
                    </div>
                    <div className="container icon">
                        <i className="i" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                </div>
                <form className="container row justify-content-around" onSubmit={uploadTask}>
                    <div className="col-6">
                        <div className="Add_img">
                            <p>{t('addCategory.singlePhoto')}</p>
                            <div className="add" {...getRootPropsSingle()}>
                                <input {...getInputPropsSingle()} />
                                {!singleImage && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addCategory.dragAndDropSingle')}</p>
                                    </div>
                                )}
                                {singleImage && (
                                    <img src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} alt="Preview" className="img" />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="project_data col-5">
                        <div className="div">
                            <p>{t('addProduct.category')}</p>
                            <div onClick={() => setShowBox(!showBox)} className="slect_category">
                                <p>{activeCategory || 'Select Category'}</p>
                                <i><FontAwesomeIcon icon={faChevronRight} /></i>
                            </div>
                            {showBox && (
                                <div>
                                    {categoryList?.data?.data.map((category) => (
                                        <div key={category.id} className="boxH">
                                            <div onClick={() => handleToggle(category.id, category.name)} className="oox_container">
                                                <div className="box_header">
                                                    <span>{category.name}</span>
                                                    {activeCategory === category.name && <span>✅</span>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="div1">
                            <label htmlFor='taskNameAr'>Name (AR)</label>
                            <input type="text" id="taskNameAr" onChange={(e) => setTaskNameAr(e.target.value)} value={taskNameAr} />
                        </div>
                        <div className="div1">
                            <label htmlFor='taskNameEn'>Name (EN)</label>
                            <input type="text" id="taskNameEn" onChange={(e) => setTaskNameEn(e.target.value)} value={taskNameEn} />
                        </div>
                        <div className="div2">
                            <label htmlFor='descriptionAr'>Description (AR)</label>
                            <input type="text" id="descriptionAr" onChange={(e) => setDescriptionAr(e.target.value)} value={descriptionAr} />
                        </div>
                        <div className="div2">
                            <label htmlFor='descriptionEn'>Description (EN)</label>
                            <input type="text" id="descriptionEn" onChange={(e) => setDescriptionEn(e.target.value)} value={descriptionEn} />
                        </div>
                        <button type="submit">
                            {Category ? 'Edit' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addCategory.errorFields')}</div>

        </div>
    );
}

export default AddCategoire;