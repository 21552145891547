import './CategoiresDetial.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import img1 from './assets/compiled/jpg/4.jpg'
import Header from './Header/Header';


function CategoiresDetial() {
  const Orders = [
    { id: 1, CREATED: '2024-08-11', CUSTOMER: 'mohammed Bikdash', TOTAL: 5, PROFIT: '89 JOD ', STATUS: '1' },
    { id: 2, CREATED: '2024-08-11', CUSTOMER: 'mohammed Bikdash', TOTAL: 5, PROFIT: '89 JOD ', STATUS: '1' },
    { id: 3, CREATED: '2024-08-11', CUSTOMER: 'mohammed Bikdash', TOTAL: 5, PROFIT: '89 JOD ', STATUS: '1' }
  ];

  const listOrders = ['NAME', 'CATEGORY', 'RATING', 'TIMING', 'DELIVERY TIME', 'ACTION'];

  const Orders2 = [
    { id: 1, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 2, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 3, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 3, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 3, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' }
  ];

  const listOrders2 = ['id', 'SKU', 'NAME', 'PRICE', 'VARIANT', 'QTY', 'DISC', 'TOTAL'];

  const [showProdcts, setShowProdcts] = useState(null);

  const toggleShowProducts = (id) => {
    setShowProdcts(showProdcts === id ? null : id);
  };

  return (
    <div className="CategoiresDetial">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i><FontAwesomeIcon icon={faCartPlus} /></i>
            <p>Orders Management</p>
          </div>
        </div>

        <div className='container'>
          <div className='row align-items-center'>
            <div className='Pending align-items-center'>

                <div className=' box1 col-4 g-0'>
                  <img src={img1}/>
                  <p>Shirt</p>
                </div>

                <div className='box3 col-7 g-0 '>
                  <div className='cont2 border-0 d-flex justify-content-between'>
                    <div>
                      <span>0</span>
                      <p>Total Orders</p>
                    </div>
                    <div>
                      <span>0</span>
                      <p>Total purchases</p>
                    </div>
                    <div>
                      <span>20</span>
                      <p>Total No Of Products</p>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder='Search' />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Recent'>Recent Orders</div>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-1 text-center' key={index}>{e}</p>
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div
                  className='Orders_border row'
                  onClick={() => toggleShowProducts(order.id)}
                >
                  <p className='col-2 text-center'>{order.id}</p>
                  <p className='col-2 text-center'>{order.CREATED}</p>
                  <p className='col-2 text-center'>{order.CUSTOMER}</p>
                  <p className='col-2 text-center'>{order.TOTAL}</p>
                  <p className='col-2 text-center'>{order.PROFIT}</p>
                  <p className='col-2 text-center'>{order.STATUS}</p>
                </div>

                <div className={`ProductsWrapper ${showProdcts === order.id ? 'active' : ''}`}>
                  <div className='Orders_border3 row justify-content-around'>
                    {listOrders2.map((item, index) => (
                      <p className='col-1 text-center' key={index}>{item}</p>
                    ))}
                  </div>
                  {Orders2.map((product, index) => (
                    <div className='Orders_border2 row justify-content-around' key={index}>
                      <p className='col-1 text-center'>{product.id}</p>
                      <p className='col-1 text-center'>{product.SKU}</p>
                      <p className='col-1 text-center'>{product.NAME}</p>
                      <p className='col-1 text-center'>{product.PRICE}</p>
                      <p className='col-1 text-center'>{product.VARIANT}</p>
                      <p className='col-1 text-center'>{product.QTY}</p>
                      <p className='col-1 text-center'>{product.DISC}</p>
                      <p className='col-1 text-center'>{product.TOTAL}</p>
                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoiresDetial;