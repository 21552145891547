import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { visitors_stats } from "./Redux/API";
import { useTranslation } from 'react-i18next';

Chart.register(CategoryScale, LinearScale, BarElement);

function SalesChart() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visitors_stat = useSelector(state => state.Data.visitors_stats);
  const loadingVisitors_stats = useSelector(state => state.Data.loadingVisitors_stats);
  const errorVisitors_stat = useSelector(state => state.Data.errorVisitors_stat);

  const [timePeriod, setTimePeriod] = useState('month');
  const [startDate, setStartDate] = useState('');

  const handleDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleFetchData = () => {
    if (startDate) {
      dispatch(visitors_stats({ start_date: startDate }));
    } else {
      alert(t('salesChart.startDate'));
    }
  };

  const labelsForPeriod = {
    week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    month: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
    year: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  };

  const dataForPeriod = {
    week: [50, 70, 80, 90, 60, 120, 110],
    month: Array.from({ length: 30 }, () => Math.floor(Math.random() * 500) + 100),
    year: [3200, 4100, 2300, 5400, 7500, 6200, 3000, 4000, 5000, 6000, 7000, 8000],
  };

  const data = {
    labels: labelsForPeriod[timePeriod],
    datasets: [
      {
        label: t('salesChart.visitors'),
        data: dataForPeriod[timePeriod],
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container mt-4">
      {/* إدخال التاريخ */}
      <div className="mb-4">
        {/* <label htmlFor="startDate">{t('salesChart.startDate')}</label> */}
        <input
          type="date"
          id="startDate"
          className="form-control"
          value={startDate}
          onChange={handleDateChange}
        />

        <div className='d-flex justify-content-between col-12 mt-3'>
          <div className='col-3'>
            <button className="btn btn-primary mt-2" onClick={handleFetchData}>
              {t('salesChart.fetchData')}
            </button>
          </div>

          <div className="btn-group" role="group" aria-label="Time period selection">
            <button
              type="button"
              className={`btn btn-primary ${timePeriod === 'week' ? 'active' : ''}`}
              onClick={() => setTimePeriod('week')}
            >
              {t('salesChart.week')}
            </button>
            <button
              type="button"
              className={`btn btn-primary ${timePeriod === 'month' ? 'active' : ''}`}
              onClick={() => setTimePeriod('month')}
            >
              {t('salesChart.month')}
            </button>
            <button
              type="button"
              className={`btn btn-primary ${timePeriod === 'year' ? 'active' : ''}`}
              onClick={() => setTimePeriod('year')}
            >
              {t('salesChart.year')}
            </button>
          </div>
        </div>
      </div>

      {/* الرسم البياني */}
      <Bar data={data} options={options} />
    </div>
  );
}

export default SalesChart;